/** LIBS */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Title from "components/title";

/* CUSTOMS */
import Loader from "components/loader";
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import {
  GetBackendPostfix,
  AdminBackendPostfix,
  BlockListBackendPath,
} from "constants/routing/backend";
import { removeMarkdownFormatting } from "lib/strings";
import HistoryCard from "pages/history";
import {
  BlockListFrontendPath,
} from "constants/routing/frontend";

/* SERVICES */
import QueueManageButtons from "../queueManageButton";
import DeletionNoticeCard from "../deletionNotice";
import { FriendlyColumns } from "components/friendlyColumns";

export default function BlockListEntry() {
  const [payloadAuthor, setPayloadAuthor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  useEffect(() => {
    authorGetRequest();
  }, [id]);

  const authorGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(BlockListBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadAuthor(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadAuthor(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const authorDescription = () => {
    var description = payloadAuthor.description;

    if (!description) {
      description = "*No description*";
    }

    return (
      <Card className={margin} title={"Block List Description"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{description}</Markdown>
        </div>
      </Card>
    );
  };

  const blockListAbout = () => {
    const headings = [
      {
        label: "Item Type",
        field: "itemType",
        width: "col-span-3",
        dataType: "write",
      },
      {
        label: "Items",
        field: "items",
        width: "col-span-9",
        dataType: "pills",
      },
      {
        label: "Tags",
        field: "tags",
        width: "col-start-1 col-span-6",
        dataType: "pills",
      },
    ];

    return (
      <Card className={margin} title={"Details"}>
        <div className="grid grid-cols-12 gap-1 my-3 w-full">
          <FriendlyColumns data={payloadAuthor} columns={headings} keyPrefix={""} />
        </div>
      </Card>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  if (!payloadAuthor) {
    return;
  }

  return (
    <>
      {payloadAuthor ? (
        <SeoHeader
          pageTitle={"Block List"}
          pageSectionTitle={payloadAuthor.title}
          description={removeMarkdownFormatting(payloadAuthor.description)}
        />
      ) : null}

      <Loader isLoading={isLoading} />

      <Title>Block List Entry</Title>

      {payloadAuthor ? (
        <QueueManageButtons
          id={id}
          frontendPath={BlockListFrontendPath}
          entityType={"blockList"}
          isDeleted={payloadAuthor.isDeleted}
        />
      ) : null}

      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {blockListAbout()}
          {<HistoryCard payload={payloadAuthor} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadAuthor} />}
          {authorDescription()}
        </div>
      </div>
    </>
  );
}

/** LIBS */
import React, { forwardRef, useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";

/* CUSTOMS */
import Heading from "components/heading";
import TextArea from "components/textArea";
import EmojiPicker from "emoji-picker-react";
import Button from "components/button";

/* SERVICES */
import { ThemeContext } from "context/themeProvider";

const CommentEditBox = forwardRef(({postText, setPostText}, ref) => {
  const markdownWidth = "md:col-span-3 col-span-6";

  const { theme } = useContext(ThemeContext);

  const emojiButton = () => {
    const [isHovered, setIsHovered] = useState(false);

    const pickerRef = useRef(null); 
    
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsHovered(false); 
      } 
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside); return () => {
        document.removeEventListener("mousedown", handleClickOutside); 
      }; 
    }, []);

    return (
      <div className="relative">
        <Button
          className={"rounded-lg"}
          onClick={() => setIsHovered(!isHovered)}>
          Emoji
        </Button>
        {isHovered && (
          <div ref={pickerRef} className="absolute top-[-500px] left-0 p-2 z-20">
            <EmojiPicker
              theme={theme}
              onEmojiClick={(e) => {
                ref.current.insertAtCursor(e.emoji);
              }} 
              emojiStyle="native"
            />
          </div>
        )}
      </div>
    );
  };

  return(
    <>
      <div className="grid grid-cols-6 gap-4 text-start">
        <div className={`${markdownWidth} w-full`}>
          <Heading>Markdown</Heading>
          <TextArea
            ref={ref}
            placeholder="Message"
            noResize={true}
            wrapperClassName="w-full col-span-6"
            className={"h-60"}
            value={postText}
            onChange={(value) => {
              setPostText(value);
            }}
          />
          <div className="flex justify-end mt-3">
            {emojiButton()}
          </div>
        </div>
        <div className={`${markdownWidth} w-full`}>
          <Heading>Preview</Heading>
          <div className={`${markdownWidth} overflow-scroll w-full`}>
            <Markdown className={"w-full h-60"}>{postText}</Markdown>
          </div>
        </div>
      </div>
    </>
  );
});

CommentEditBox.displayName = "TextBox";

CommentEditBox.propTypes = {
  title: PropTypes.string.isRequired,
  postText: PropTypes.string,
  setPostText: PropTypes.func,
  className: PropTypes.string,
};
  
export default CommentEditBox;
/** LIBS */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import Markdown from "react-markdown";

/* CUSTOMS */
import ButtonLinksTabbed from "components/buttonLinksTabbed";
import Card from "components/card";
import { fetchGet } from "lib/fetch";

import { AboutPage, GettingStartedPage, RulesPage, DictionaryPage, FaqPage } from "./pages";

/* CONSTANTS */
import {
  HelpFrontendPath,
  GettingStartedFrontendPath,
  RulesFrontendPath,
  FaqFrontendPath,
  DictionaryFrontendPath,
} from "constants/routing/frontend";
import Title from "components/title";
import SeoHeader from "components/seoHeader";

export default function HelpRoutes() {
  const [text, setText] = useState([]);
  const [raw, setRaw] = useState("");
  const [heading, setHeading] = useState("");
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  const tabbedLinks = [
    {
      text: "About",
      path: HelpFrontendPath,
      pathPart: "",
      element: <AboutPage setRaw={setRaw} setHeading={setHeading} />,
    },
    {
      text: "Getting Started",
      path: HelpFrontendPath + GettingStartedFrontendPath,
      pathPart: GettingStartedFrontendPath,
      element: <GettingStartedPage setRaw={setRaw} setHeading={setHeading}/>,
    },
    {
      text: "Rules",
      path: HelpFrontendPath + RulesFrontendPath,
      pathPart: RulesFrontendPath,
      element: <RulesPage setRaw={setRaw} setHeading={setHeading}/>,
    },
    {
      text: "Dictionary",
      path: HelpFrontendPath + DictionaryFrontendPath,
      pathPart: DictionaryFrontendPath,
      element: <DictionaryPage setRaw={setRaw} setHeading={setHeading}/>,
    },
    {
      text: "FAQ",
      path: HelpFrontendPath + FaqFrontendPath,
      pathPart: FaqFrontendPath,
      element: <FaqPage setRaw={setRaw} setHeading={setHeading}/>,
    },
  ];

  useEffect(() => {
    if (raw === "") {
      return;
    }

    fetchGet(raw, null).then((resp) => {
      var result = resp
        .split(/((?<!#)## .*?\n)/g)
        .map((x) => x.trim())
        .filter((x) => x);

      var temp = [];

      for (var i = 0, j = 0; i < result.length; i += 2, j++) {
        temp[j] = {
          heading: result[i].replace("## ", ""),
          text: result[i + 1],
        };
      }

      setText(temp);
    });
  }, [raw]);

  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll("h2"));
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, [text]);

  // source: https://codepen.io/emgoto/pen/jOyORjZ?editors=0110
  const NavbarHeadings = ({ headings }) => {
    return(
      <Card 
        headingId={"cardHeading"}
        title={"ToC"}
        className={"mb-4"}>
        {headings.map((heading, i) => {
          return (
            <React.Fragment key={`navbar-${i}`}>
              <a
                href={`#${heading.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector(`#${heading.id}`).scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                {heading.title}
              </a>

              {Object.keys(headings).length - 1 !== i ? (
                <nobr className="mr-3 ml-3 text-contrastChangeText">•</nobr>
              ) : null}
            </React.Fragment>
          );
        })}
      </Card>
    );
  };

  NavbarHeadings.propTypes = {
    headings: PropTypes.array.isRequired,
  };
  
  const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];

    headingElements.forEach((heading) => {
      const { innerText: title, id } = heading;

      if (heading.nodeName === "H2") {
        nestedHeadings.push({ id, title, items: [] });
      }
    });

    return nestedHeadings;
  };

  return (
    <>
      <SeoHeader 
        pageTitle={"Help"} 
      />
      
      <ButtonLinksTabbed id="helpTab" links={tabbedLinks} />
      <Title>{heading}</Title>

      {text.length !== 0 ? (
        <NavbarHeadings className="mt-3" headings={nestedHeadings} />
      ) : null}
      <Routes>
        {tabbedLinks.map(({ pathPart, element }, i) => {
          return (
            <Route key={`help-${i}`} path={pathPart} exact element={element} />
          );
        })}
      </Routes>
      <div className="text-left">
        {text.map(({ heading, text }, i) => {
          return (
            <Card
              key={`card-${i}`}
              headingId={`heading-${i}`}
              title={heading}
              className={"mb-4"}
            >
              <Markdown>{text}</Markdown>
            </Card>
          );
        })}
      </div>
    </>
  );
}

import React from "react";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  useEffect(() => {
    const persistentUserData = localStorage.getItem("user-data");
    const storedValue = JSON.parse(persistentUserData);

    if (storedValue) {
      setUserContext_(storedValue);
    }
  }, []);

  const [userContext, setUserContext_] = useState({
    username: null,
    permissions: [],
    userId: null,
    lastReadDateTime: 0,
  });

  const setUserContext = ({ isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled }) => {
    localStorage.setItem(
      "user-data",
      JSON.stringify({ isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled })
    );
    setUserContext_({ isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled });
  };

  const userContent = {
    userContext,
    setUserContext,
  };

  return (
    <UserContext.Provider value={userContent}>{children}</UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.any,
};

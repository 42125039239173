import DOMPurify from "dompurify";
import {marked} from "marked";

export function ToTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function ToLabelValue(strArray) {
  if (!strArray) {
    strArray = [];
  }

  if (typeof strArray !== "string" && !Array.isArray(strArray)) {
    console.error(`ToLabelValue: invalid datatype: ${typeof strArray}`);
    return;
  }

  if (typeof strArray === "string") {
    strArray = [strArray];
  }

  strArray = strArray.map((x) => {
    return { label: x, value: x };
  });

  return strArray;
}

export function ToLabelValueFromSource(value, source) {
  if (!source || Object.keys(source).length === 0) {
    return [];
  }
  
  // Normalize value to an array if it's a string
  const valuesArray = Array.isArray(value) ? value : [value];

  // Create a map to keep track of the order of values
  const valueIndexMap = new Map(valuesArray.map((v, i) => [v, i]));

  return source
    .filter((item) => valueIndexMap.has(item.value)) // Filter items that match
    .sort((a, b) => valueIndexMap.get(a.value) - valueIndexMap.get(b.value)); // Sort based on the original array's order
}

export function ToLabelValueFromMap(source) {
  if (!source || Object.keys(source).length === 0) {
    return;
  }

  var ret = [];
  Object.entries(source).forEach(([key, value]) => {
    ret.push({ label: value, value: key });
  });

  return ret;
}

export function GetFriendlyNameFromSource(value, source) {
  let retVal;
  source.map((x) => {
    if (x.value === value) {
      retVal = x.label;
    }

    return null;
  });
  return retVal;
}

export function MapValueToLabelValue(value, options) {
  let retVal = [{ label: "", value: "" }];

  options.forEach((item) => {
    if (item.value === value) {
      retVal = [item];
      return;
    }
  });

  return retVal;
}

export function CamelToTitleCase(camelCaseStr) {
  // Insert spaces before capital letters
  const spacedStr = camelCaseStr.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of the result
  const titleCaseStr = spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);

  return titleCaseStr;
}

export function removeMarkdownFormatting(markdownText) {
  if (!markdownText){
    return "";
  }

  // Parse the Markdown into HTML
  const html = marked(markdownText);

  // Sanitize the HTML to prevent XSS attacks
  const cleanHtml = DOMPurify.sanitize(html);

  // Create a temporary DOM element to strip HTML tags
  const tempElement = document.createElement("div");
  tempElement.innerHTML = cleanHtml;

  // Extract plain text from the HTML
  return tempElement.textContent || tempElement.innerText || "";
}

export function formatDbFieldString(input) {
  const segments = input.split(".");
  
  const uniqueWords = new Set();
  const wordList = new Set();

  const splitCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
  };

  const addWordForms = (word) => {
    if (wordList.has(word)){
      return;
    }

    uniqueWords.add(word);

    wordList.add(word);
    
    if (word.endsWith("s")) {
      wordList.add(word.slice(0, -1)); 
    } else {
      wordList.add(word + "s");
    }
  };

  segments.forEach(segment => {
    const words = splitCamelCase(segment);
      
    // Capitalize the first letter and add to the Set
    words.forEach(word => {
      const formattedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      
      addWordForms(formattedWord);
    });
  });

  return Array.from(uniqueWords).join(" ").trim();
}

export function largeArrayToBase64(byteArray) {
  const uint8Array = new Uint8Array(byteArray);
  const chunks = [];
  const chunkSize = 65536; // 64kB

  for (let i = 0; i < uint8Array.length; i += chunkSize) {
    chunks.push(String.fromCharCode.apply(null, uint8Array.subarray(i, i + chunkSize)));
  }
  
  const sss = btoa(chunks.join(""));

  return sss;
}

export function getLinkFromLegacyId(legacyId) {
  if (legacyId.startsWith("whirlpool")) {
    const splitted = legacyId.split("/");
    return`http://donut.parodius.com/?func=${splitted[1]}item&${splitted[1]}key=${splitted[2]}`;
  } 

  return `https://www.romhacking.net/${legacyId}`;
}
/** LIBS */
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import Title from "components/title";
import TextBox from "components/textBox";
import Button from "components/button";
import Card from "components/card";
import Checkbox from "components/checkBox";

/* CONSTANTS */
import { LoginBackendPath } from "constants/routing/backend";
import { UserContext } from "context/user";
import {
  ForumFrontendPath,
  MfaFrontendPath,
  RegistrationFrontendPath,
  ResetFrontendPath,
  UserFrontendPath,
} from "constants/routing/frontend";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setUserContext } = useContext(UserContext);
  const navigate = useNavigate();

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      username: username,
      password: password,
    };

    fetchPost(LoginBackendPath, body)
      .then((resp) => {
        const { isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled } = resp;
        if (mfaEnabled) {
          navigate(UserFrontendPath + MfaFrontendPath);
          return;
        }

        const userData = { isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled };
        setUserContext(userData);

        toast.success("Logged in");
        navigate(ForumFrontendPath);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setUserContext({ isLoggedIn: false, permissions: [], username: null, userId: null, lastReadDateTime: 0, mfaEnabled: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const passwordType = showPassword ? "" : "password";

  return (
    <>
      <SeoHeader pageTitle={"Search"} />
      <Loader isLoading={isLoading} />

      <Title>Login</Title>

      <Card title="Form">
        <div className="col-span-12 flex justify-center">
          <TextBox id={"username-textBox"} className={"mb-3 w-full max-w-xs"} placeholder={"Username"} onChange={setUsername} />
        </div>
        <div className="col-span-12 flex justify-center">
          <TextBox
            id={"username-textBox"}
            className={"mb-3 w-full max-w-xs"}
            placeholder={"Password"}
            type={passwordType}
            onChange={setPassword}
          />
        </div>
        <div className="col-span-12 w-full text-center mb-3">
          <Checkbox label="Show Password" checked={showPassword} onChange={setShowPassword} />
        </div>

        <div className="col-span-12 flex justify-center">
          <Button className={"rounded-lg max-w-xs"} onClick={hackRequest}>
            Submit
          </Button>
        </div>
        <div className="col-span-12 flex justify-center mt-10">
          <Link to={UserFrontendPath + ResetFrontendPath} className=" ml-1">
          Forgot your password?
          </Link>
        </div>

        <div className="col-span-12 flex justify-center mt-5">
          {"Don't have any account?"}
          <Link to={UserFrontendPath + RegistrationFrontendPath} className=" ml-1">
            Sign Up!
          </Link>
        </div>
      </Card>
    </>
  );
}

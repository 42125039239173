/* LIBS */
import React from "react";
import Pill from "components/pill";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/* ICONS */
import Time from "components/time";
import { extractFromObjectWithPath } from "lib/extractFromObjectWithPath";

export const FriendlyColumns = ({ data, columns, keyPrefix }) => {
  const headingLabel = "text-left";
  return (
    <>
      {columns.map((c, i) => {
        var ccc;
        c.width = c.width || "col-span-12 md:col-span-6 lg:col-span-3 sm:col-span-12";

        var className = `${c.width} ${headingLabel}`;
        const key = `friendlyTable-c-${keyPrefix}-${i}`;

        var fieldValue = data;
        if (c.field){
          fieldValue = extractFromObjectWithPath(c.field, data);
        }

        switch (c.dataType) {
        case "link":
          {
            const id = extractFromObjectWithPath(c.idField, data);
            var to = c.to;
            if (typeof to === "function") {
              to = to(data);
            }

            ccc = (
              <Link className="text-sm" to={to + id}>
                {fieldValue}
              </Link>
            );
          }
          break;

        case "write":
          if (typeof fieldValue === "number") {
            fieldValue = fieldValue.toLocaleString();
          }

          ccc = <div>{fieldValue}</div>;
          break;
        case "dateTime":
          ccc = <Time time={fieldValue}/>;
          break;
        case "pills": {
          className = `${c.width} text-left`;

          ccc = (
            <div className="flex flex-wrap">
              {data[c.field] && data[c.field].map((element, i) => {
                return <Pill key={`friendlyTable-pills-${keyPrefix}-${i}`} color={"blue"} className={"m-1"} text={element} />;
              })}
            </div>
          );
          break;
        }

        case "custom":
          ccc = c.custom(fieldValue);
          break;
        default:
          break;
        }

        return (
          <div key={key} className={className}>
            <h3 className={"text-contrastChangeText"}>{c.label}</h3>
            {ccc}
          </div>
        );
      })}
    </>
  );
};

FriendlyColumns.propTypes = {
  keyPrefix: PropTypes.any.isRequired,
  data: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.string,

      idField: PropTypes.string,
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
    })
  ).isRequired,
};

/** LIBS */
import React from "react";
import PropTypes from "prop-types";

/* ICONS */
import { FaCheckCircle as SuccessIcon } from "react-icons/fa";
import { HiXCircle as FailureIcon} from "react-icons/hi";

export default function ValidatePasswords({password1, password2, setIsPasswordGood, setPasswordErrors}) {

  const containerClass = "flex items-center justify-start col-span-12";

  var isGood = true;
  const createLine = (text, pass) => {
    if (pass) {
      return (
        <div className={containerClass}>
          <SuccessIcon className="mr-2 text-green-500" />
          {text}
          <br />
        </div>
      );
    }
    isGood = false;
    return (
      <div className={containerClass}>
        <FailureIcon className="mr-2 text-red-500 col-span-12" />
        {text}
        <br />
      </div>
    );
  };

  const temp = [];
  temp.push(createLine("10 characters minimum.", password1.length >= 10));
  temp.push(createLine("At least one uppercase character.", /[A-Z]/.test(password1)));
  temp.push(createLine("At least one lowercase character.", /[a-z]/.test(password1)));
  temp.push(createLine("At least one numeric character.", /[0-9]/.test(password1)));
  temp.push(createLine("At least one special character.", /[^A-Za-z0-9]/.test(password1)));
  temp.push(createLine("Password fields must match", password1 === password2));

  setIsPasswordGood(isGood);
  setPasswordErrors(temp);
}

ValidatePasswords.propTypes = {
  password1: PropTypes.string,
  password2: PropTypes.string,

  setIsPasswordGood: PropTypes.func,
  setPasswordErrors: PropTypes.func,
};
  
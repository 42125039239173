/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Title from "components/title";

/* CUSTOMS */
import Loader from "components/loader";
import { fetchPost } from "lib/fetch";
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import {
  GetBackendPostfix,
  UserBackendPostfix,
  AdminBackendPath,
  FieldValuesBackendPostfix,
  UpdateBackendPostfix,
} from "constants/routing/backend";

/* SERVICES */
import { UserContext } from "context/user";
import { PermissionAdmin } from "constants/permissions";
import Card from "components/card/index";
import MultiSelectAutocomplete from "components/multiSelectAutocomplete/index";
import Button from "components/button/index";

export default function UserEdit2() {
  const [payloadUser, setPayloadUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const { id } = useParams();

  const { userContext } = useContext(UserContext);
  const { isLoggedIn , permissions } = userContext;

  useEffect(() => {
    userGetRequest();
  }, [id]);

  const userGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(AdminBackendPath + UserBackendPostfix + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadUser(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userEditRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
      tags: tags,
    };

    fetchPost(AdminBackendPath + UserBackendPostfix + UpdateBackendPostfix, body)
      .then((resp) => {
        toast.success(resp.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isLoggedIn){
    return;
  }

  if (!permissions.includes(PermissionAdmin)) {
    toast.error("You do not have permission to view this page.");
    return;
  }

  var authorName = payloadUser && payloadUser.username;

  return (
    <>
      <SeoHeader
        pageTitle={"User"}
        pageSectionTitle={payloadUser && payloadUser.username}
      />

      <Loader isLoading={isLoading} />

      <Title>{`Edit ${authorName}`}</Title>

      <Card className="m-8" title={"Tags"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <MultiSelectAutocomplete
            className="max-lg:col-span-6 md:col-span-4"
            placeholder="Tags"
            field="tags"
            searchUri={AdminBackendPath + UserBackendPostfix}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
            isCreatable={true}
          />
        </div>
      </Card>
      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 rounded-md"} onClick={userEditRequest}>
          Submit
        </Button>
      </div>
    </>
  );
}

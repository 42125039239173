/** LIBS */
import React, { useState, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Card from "components/card";
import { fetchPost } from "lib/fetch";

/* CONSTANTS */
import Button from "components/button";
import Loader from "components/loader";
import { ForumBackendPath, GetBackendPostfix, UpdateBackendPostfix } from "constants/routing/backend";
import { ForumFrontendPath } from "constants/routing/frontend";
import CommentEditBox from "./commentEditBox";

/* ICONS */

/* SERVICES */
import { UserContext } from "context/user";

export default function PostUpdate() {
  const { userContext } = useContext(UserContext);
  const { isLoggedIn } = userContext;

  const [isLoading, setIsLoading] = useState(false);
  const [postText, setPostText] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const postId = queryParams.get("postId");
  const topicId = queryParams.get("topicId");

  const navigate = useNavigate();

  const postBoxRef = useRef(null);


  useEffect(() => {
    postRequest();
  }, []);

  const forumPostUpdate = () => {
    setIsLoading(true);

    const body = {
      id: postId,

      text: postText,
    };

    fetchPost(ForumBackendPath + "/post" + UpdateBackendPostfix, body)
      .then(async (resp) => {
        navigate(`${ForumFrontendPath}/topic/${topicId}?${new URLSearchParams({postId })}`);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postRequest = () => {
    setIsLoading(true);

    fetchPost(ForumBackendPath + "/post" + GetBackendPostfix, { id: postId })
      .then((resp) => {
        setPostText(resp.text);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!postId) {
    return;
  }

  if (!isLoggedIn) {
    return;
  }

  return (
    <div className="space-y-2 mb-2">
      <SeoHeader pageTitle={"Forum - Update Post"} />
      <Title>{"Edit Post"}</Title>
      <Loader isLoading={isLoading} />

      <Card title="Post">
        <CommentEditBox ref={postBoxRef} className={"my-2"} postText={postText} setPostText={setPostText} />
      </Card>

      <div className="flex items-center justify-end">
        <Button className="rounded-lg" onClick={forumPostUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
}

/** LIBS */
import React, { useState, useRef, useContext, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";

/* CUSTOMS */
import { Link } from "react-router-dom";
import defaultAvatar from "../../assets/defaultAvatar.png";
import Markdown from "react-markdown";
import { formatEpochToUTC } from "lib/time";
import DropdownDot from "components/dropdownDot";
import Paper from "components/paper";
import Button from "components/button";
import Time from "components/time";

/* CONSTANTS */
import { PermissionAMod } from "constants/permissions";
import { EntryPostfix, ForumFrontendPath, TopicFrontendPath, UpdatePostfix, UserFrontendPath } from "constants/routing/frontend";

/* ICONS */
import { MdEmojiEmotions } from "react-icons/md";

/* SERVICES */
import { UserContext } from "context/user";
import { fetchPost } from "lib/fetch";
import { ForumBackendPath } from "constants/routing/backend";
import { toast } from "react-toastify";
import Loader from "components/loader";
import { CustomEmojiPicker } from "components/customEmojiPicker";

const CommentEntry = forwardRef((props) => {
  const {
    payload,
    setPayload,
    i,

    payloadTopic,
    postText,
    setPostText,
    topicId,
    setModalText,
    setModalAction,
    setModalOpen,
    locatePostToScroll,
    deletePostRequest,
    highlightPostId,
    postBoxRef, 
    commentTextboxScrollRef,
  } = props;

  const x = payload.entries[i];

  const { userContext } = useContext(UserContext);
  const { isLoggedIn, userId, username, permissions } = userContext;

  const canDelete = permissions.includes(PermissionAMod);
  const canPost = isLoggedIn && (permissions.includes(PermissionAMod) || (payloadTopic && !payloadTopic.isLocked));

  const pickerRef = useRef(null);
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var dotDropDownOptions = [];

  function quoteText(text) {
    // Single line case
    if (text.indexOf("\n") === -1) {
      return "> " + text;
    }
  
    // Multi-line case: process each line
    return text
      .split("\n")
      .map((line) => "> " + line)
      .join("\n");
  }
  
  function formatNames(names) {
    switch (names.length) {
    case 1:
      return names[0];
    case 2:
      return `${names[0]} and ${names[1]}`;
    case 3:
      return `${names[0]}, ${names[1]}, and ${names[2]}`;
    default:
      return `${names[0]}, ${names[1]}, and ${names.length - 2} others`;
    }
  }
  
  const emojiList = (entry, userId) => {
    const reactionStats = {};
    entry.reactions && entry.reactions.forEach((x, i)=> {
      if (!reactionStats[x.emoji]){
        reactionStats[x.emoji] = {users: [], originalId: i};
      }
  
      reactionStats[x.emoji].users.push({userId: x.userId, username: x.userName});
    });
    
    return(
      <>
        {
          Object.keys(reactionStats).length > 0 && Object.keys(reactionStats).map((emoji, emojiId) => {
            const userList = reactionStats[emoji].users;
            const tooltipText = `Reacted by ${formatNames(userList.map(x => x.username))}`;
  
            const didUserReact = userList.some(x => x.userId === userId);
            return(
              <>
                <div 
                  key={`reaction-${emojiId}`} data-tooltip-id={`reaction-post-${entry.id}-${emojiId}`} 
                  data-tooltip-content={tooltipText}
                  data-tooltip-place="top"
                >
                  <div 
                    className={`cursor-pointer pt-2 pr-4 pl-4 pb-2 ${didUserReact  
                      ? "bg-reactionColor": "bg-textBoxColor"} rounded-lg 
              
              
              ${ didUserReact? "outline" :"hover:outline"} outline-1 outline-borderColor opacity-1
              select-none`}
                    onClick={async()=> {
  
                      try {
                        const temp = { ...payload };

                        var entry = temp.entries[i];

                        if (!entry.reactions){
                          entry.reactions = [];
                        }
  
                        const doRemoveEmoji = entry.reactions.some(x => x.userId === userId && x.emoji === emoji);

                        if (doRemoveEmoji){
                          await removeEmoji(entry.id);

                          entry.reactions = entry.reactions.filter(x => x.userId !== userId);
                          temp.entries[i] = entry;

                          setPayload(temp);
                        } else {
                          await addEmoji(entry.id, emoji);

                          entry.reactions = entry.reactions.filter(x => x.userId !== userId);

                          entry.reactions.push({
                            userId: userId,
                            userName: username,
                            emoji	: emoji
                          });

                          temp.entries[i] = entry;
    
                          setPayload(temp);
                        }

                      } catch(e){
                        toast.error(e.message);
                      }
                    }}
                  
                  >
                    <div className="flex">
  
  
                      <div className="mr-2">{emoji}</div> {userList.length}
                    </div>
                  </div>
      
                </div>
                <Tooltip id={`reaction-post-${entry.id}-${emojiId}`} className="z-50" />
              </>
            );
          })
        }
      </>
    );
  };
  
  const removeEmoji = async(postId) => {
    setIsLoading(true);
  
    const body = {
      postId: postId,
    };
  
    await fetchPost(ForumBackendPath + "/post" + "/reaction" + "/delete", body)
      .then((resp) => {
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(()=> {
        setIsLoading(false);
      });
  };

  const addEmoji = async(postId, emoji) => {
    setIsLoading(true);
  
    const body = {
      postId: postId,
      reaction: emoji,
    };
  
    await fetchPost(ForumBackendPath + "/post" + "/reaction" + "/add", body)
      .then((resp) => {
        setIsHovered(false);
      })
      .catch((e) => {
        throw new Error(e.message);
      })
      .finally(()=> {
        setIsLoading(false);
      });
  };


  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsHovered(false); 
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); return () => {
      document.removeEventListener("mousedown", handleClickOutside); 
    }; 
  }, []);

  if (canPost) {
    dotDropDownOptions.push({
      label: "Quote",
      onClick: () => {
        var temp = postText;
        temp = `${temp}\n> [*Posted by ${x.createdBy} @ ${formatEpochToUTC(x.createdDate)}*](?${new URLSearchParams({
          postId: x.id,
        })})\n> \n${quoteText(x.text)}\n`;
        setPostText(temp);
        postBoxRef.current.setValue(temp);

        commentTextboxScrollRef.current.jumpToSection();
      },
    });
  }

  dotDropDownOptions.push({
    label: "Permalink",
    onClick: async () => {
      const temp =
        ForumFrontendPath +
        TopicFrontendPath +
        "/" +
        topicId +
        "?" +
        new URLSearchParams({
          postId: x.id,
        });

      try {
        navigator.clipboard.writeText(temp);
      } catch (err) {
        alert(`Clipboard access denied:\n${window.location.protocol}//${window.location.host}${temp}`);
      }
    },
  });

  if (permissions.includes(PermissionAMod) || x.createdBy === username) {
    dotDropDownOptions.push({
      label: "Edit",
      onClick: () => {
        navigate(ForumFrontendPath + "/post" + UpdatePostfix + `/?${new URLSearchParams({ postId: x.id, topicId })}`);
      },
    });
  }

  if (canDelete) {
    dotDropDownOptions.push({
      label: "Delete",
      isSecondary: true,
      onClick: () => {
        setModalText("Are you sure you want to delete this post? This action cannot be undone.");
        setModalAction(() => () => deletePostRequest(x.id));
        setModalOpen(true);
      },
    });
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <Paper className={`my-2 ${x.id === highlightPostId ? "opacity-90" : ""}`}>
        <div className="grid grid-cols-12">
          <div className="max-xl:col-span-2 col-span-1">
            <div className="text-left">
              <Link className="font-bold" to={UserFrontendPath + EntryPostfix + x.createdByUserId}>
                <h6 id={x.id}>{x.createdByUsername}</h6>
              </Link>

              <img src={defaultAvatar} alt="" />

              <div className="text-sm">Sr. Member</div>
              <div className="text-sm">Posts: 403</div>
            </div>
          </div>
          <div className="max-xl:col-span-10 col-span-11 text-left">
            <div className="flex justify-between">
              <div className="text-xs mb-1 flex justify-start">
              Posted <Time className="ml-1" time={x.createdDate} fromNow />
              </div>
              <div className="text-xs mb-1">
                {x.updatedDate === 0 || (
                  <div className="text-xs mb-1 flex justify-start italic">
                  Last Edit: <Time className="mr-1 ml-1" time={x.updatedDate} fromNow />
                    {" by "}
                    <Link className="ml-1">{x.updatedBy}</Link>
                  </div>
                )}
              </div>
            </div>

            <hr className="mb-2" />
            <Markdown
              className="break-words"
              components={{
                a: ({ href, children }) => {
                // Check if the link is internal (starts with "/")
                  if (href && (href.startsWith("/") || href.startsWith("?"))) {
                    return (
                      <div
                        className="linkStyle"
                        onClick={() => {
                          const postId = href.replace("?postId=", "");
                          locatePostToScroll(postId);
                        }}
                      >
                        {children}
                      </div>
                    );
                  }

                  // If it's an external link, use the default <a> behavior
                  return (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      {children}
                    </a>
                  );
                },
              }}
            >
              {x.text}
            </Markdown>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
       
          {emojiList(x, userId, setIsLoading)}

          <div className="relative">
            {!isLoggedIn || <Button className={"rounded-lg"} onClick={() => setIsHovered(!isHovered)}>
              <div className="flex"><div className="mr-2"><MdEmojiEmotions /></div> React</div>
            </Button>}
            {isHovered && (
              <div ref={pickerRef} className="absolute top-[-470px] left-[-200px] p-2 z-20">
                <CustomEmojiPicker
                  onEmojiClick={async (e) => {

                    try {
                      const emojiChar = e.emoji;
                      await addEmoji(x.id, emojiChar);

                      const temp = { ...payload };

                      var entry = temp.entries[i];

                      if (!entry.reactions){
                        entry.reactions = [];
                      }

                      entry.reactions = entry.reactions.filter(x => x.userId !== userId);
                      entry.reactions.push({
                        userId,
                        userName: username,
                        emoji	: emojiChar
                      });

                      temp.entries[i] = entry;

                      setPayload(temp);
                    } catch(e){
                      toast.error(e.message);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div>
            {dotDropDownOptions.length > 3 ? (
              <DropdownDot options={dotDropDownOptions} />
            ) : (
              dotDropDownOptions.map((x, i) => {
                return (
                  <Button key={`eee-${i}`} className="rounded-lg mr-2" onClick={x.onClick} secondary={x.isSecondary}>
                    {x.label}
                  </Button>
                );
              })
            )}
          </div>
        </div>
      </Paper>
    </>
  );
});

CommentEntry.displayName = "TextBox";

CommentEntry.propTypes = {
  topicId: PropTypes.string.isRequired,

  setPayload: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  
  payloadTopic: PropTypes.string.isRequired,
  postText: PropTypes.string.isRequired,
  setPostText: PropTypes.func.isRequired,

  setModalText: PropTypes.func.isRequired,
  setModalAction: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,

  locatePostToScroll: PropTypes.func.isRequired,
  deletePostRequest: PropTypes.func.isRequired,
  highlightPostId: PropTypes.string.isRequired,

  postBoxRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  commentTextboxScrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

export default CommentEntry;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import EmojiPicker from "emoji-picker-react";

/* SERVICES */
import { ThemeContext } from "context/themeProvider";

export const CustomEmojiPicker = ({ onEmojiClick, ...otherProps }) => {
  const { theme } = useContext(ThemeContext);

  // https://cdn.jsdelivr.net/npm/emoji-datasource-twitter

  return (
    <EmojiPicker
      theme={theme}
      {...otherProps}
      onEmojiClick={onEmojiClick}
      preload={true}
      customEmojis={[
        {
          names: ["+1", "thumbs up"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/1f44d.png",
          id: "👍",
        },
        {
          names: ["smile"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/1f60a.png",
          id: "😊",
        },
        {
          names: ["-1", "thumbs down"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/1f44e.png",
          id: "👎",
        },
        {
          names: ["One"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/0031-fe0f-20e3.png",
          id: "1️⃣",
        },
        {
          names: ["Two"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/0032-fe0f-20e3.png",
          id: "2️⃣",
        },
        {
          names: ["Three"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/0033-fe0f-20e3.png",
          id: "3️⃣",
        },
        {
          names: ["Four"],
          imgUrl: "https://cdn.jsdelivr.net/npm/emoji-datasource-twitter/img/twitter/64/0034-fe0f-20e3.png",
          id: "4️⃣",
        },
      ]}
      disableAutoFocus={true}
      skinTonesDisabled={true}
      categories={[
        {
          category: "custom", // You can define a custom category for these emojis
        },
      ]}
      emojiStyle="native"
    />
  );
};

// Define prop types for the component
CustomEmojiPicker.propTypes = {
  onEmojiClick: PropTypes.func.isRequired,
};

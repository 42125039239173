import React from "react";
import PropTypes from "prop-types";
import Heading from "../heading";
import { Link } from "react-router-dom";

export default function Card({ className, title, children, headingId, to, noMargin, ...otherProps }) {
  const headingText = (
    <Heading className="break-words" id={headingId}>
      {title}
    </Heading>
  );

  return (
    <div className={`${className} shadow-lg`}>
      <div 
        className="border-borderColor border-t-2 border-r-2 border-l-2 text-center pr-4 pl-4 flex justify-center bg-menuColor rounded-t-lg">
        {to ? (
          <Link className="text-linkBlue hover:text-linkBlueHover break-all" to={to}>
            {headingText}
          </Link>
        ) : (
          headingText
        )}
      </div>
      <div
        {...otherProps}
        className={`border-borderColor border-2 rounded-b-lg ${noMargin || "px-6 py-4"} shadow-lg bg-cardBg text-textWithContrastLightBg`}
      >
        {children}
      </div>
    </div>
  );
}


Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  disableBackground: PropTypes.bool,
  children: PropTypes.any.isRequired,
  headingId: PropTypes.any,
  to: PropTypes.string,
  noMargin: PropTypes.bool,
};

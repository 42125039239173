import React from "react";

/* ICONS */
import ForumIcon1 from "assets/icon-forum-1.png";
import ForumIcon2 from "assets/icon-forum-2.png";

import LockIcon1 from "assets/icon-lock-1.png";
import LockIcon2 from "assets/icon-lock-2.png";

import PinIcon1 from "assets/icon-pin-1.png";
import PinIcon2 from "assets/icon-pin-2.png";

const lockedIcon = {
  true: LockIcon2,
  false: LockIcon1,
};

const pinnedIcon = {
  true: PinIcon2,
  false: PinIcon1,
};

const standardIcon = {
  true: ForumIcon2,
  false: ForumIcon1,
};

export const GenerateStatusIcons = (data, lastReadDateTime) => {
  const className = "pixelImage p-2 scale-[1.7]";

  var isUnread = (data.updatedDate > lastReadDateTime);
  const hoverText = isUnread ? "New" : "Not New";

  const renderImage = (src) => {
    return <img src={src} className={className} alt="" title={hoverText} />;
  };

  const icons = [];

  if (!(data.isPinned || data.isLocked)) {
    icons.push(renderImage(standardIcon[isUnread]));
  }

  if (data.isPinned) {
    icons.push(renderImage(pinnedIcon[isUnread]));
  }

  if (data.isLocked) {
    icons.push(renderImage(lockedIcon[isUnread]));
  }

  return icons;
};


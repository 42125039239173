/** LIBS */
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useParams } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import Title from "components/title";
import TextBox from "components/textBox";
import Button from "components/button";
import Card from "components/card";
import Checkbox from "components/checkBox";
import InlineLoader from "components/loaderInline";
import ValidatePasswords from "./validatePasswords";

/* CONSTANTS */
import { UpdateBackendPostfix, UserBackendPath } from "constants/routing/backend";
import { UserContext } from "context/user";
import { LoginFrontendPath, UserFrontendPath } from "constants/routing/frontend";

export default function Reset2() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [isPasswordGood, setIsPasswordGood] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [registration1Success, setRegistration1Success] = useState("");

  const { setUserContext } = useContext(UserContext);
  const navigate = useNavigate();
  const { jwtToken } = useParams();

  const passwordType = showPassword ? "" : "password";

  useEffect(() => {
    validateJWT();
  }, []);

  const validateJWT = () => {
    setIsLoading(true);

    const body = {
      jwtToken: jwtToken,
    };

    fetchPost(UserBackendPath + "/guest" + UpdateBackendPostfix + "/password2", body)
      .then((resp) => {
        setRegistration1Success(true);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const passwordUpdateRequest = () => {
    setIsLoading(true);

    const body = {
      password: password1,
      jwtToken: jwtToken,
    };

    fetchPost(UserBackendPath + "/guest" + UpdateBackendPostfix + "/password3", body)
      .then((d) => {
        toast.success(d.message);
        navigate( UserFrontendPath + LoginFrontendPath);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ValidatePasswords({ password1, password2, setIsPasswordGood, setPasswordErrors });
  }, [password1, password2]);

  if (!registration1Success) {
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={"Search"} />
      <Loader isLoading={isLoading} />

      <Title>Reset</Title>

      <Card title="Form">
        <div className="grid grid-cols-11 gap-3">
          <TextBox
            id={"textBox-password1"}
            className={"w-full sm:col-span-5 col-span-12"}
            placeholder={"Password"}
            type={passwordType}
            value={password1}
            onChange={setPassword1}
          />

          <TextBox
            id={"textBox-password2"}
            className={"w-full sm:col-span-5 col-span-12"}
            placeholder={"Password (Re-enter)"}
            type={passwordType}
            value={password2}
            onChange={setPassword2}
          />

          <div data-tooltip-id="tooltip" className="w-10 max-md:hidden grid grid-cols-1 gap-3">
            <InlineLoader isSuccess={isPasswordGood} isLoading={false} />
            <Tooltip id="tooltip" place="top" effect="solid" className="text-left">
              {passwordErrors}
            </Tooltip>
          </div>

          <div className="col-span-12 w-full">
            <Checkbox label="Show Password" checked={showPassword} onChange={setShowPassword} />
          </div>
        </div>

        <div className="flex justify-start gap-3 md:hidden mt-3 text-left">
          <div>{passwordErrors}</div>
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button className="mt-3 rounded-lg" onClick={passwordUpdateRequest}>
            Save
          </Button>
        </div>
      </Card>
    </>
  );
}

/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-comments-section/dist/index.css";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import Time from "components/time";
import Pill from "components/pill";
import RadioButtons from "components/radioButtons";
import Button from "components/button";
import Heading from "components/heading";
import HistoryCard from "pages/history";

/* CONSTANTS */
import {
  CreateBackendPostfix,
  DeleteBackendPostfix,
  GetBackendPostfix,
  QueueBackendPath,
  UpdateBackendPostfix,
} from "constants/routing/backend";
import { SubmissionFrontendPath, CommentPostfix, DatabaseFrontendPath, EntryPostfix } from "constants/routing/frontend";
import { websocketKeyAlert } from "constants/models";

/* SERVICES */
import { UserContext } from "context/user";
import CommentSection from "components/commentSection";
import { queueActionColorTable } from "constants/models";
import { WebSocketContext } from "context/webSocket";
import PopupBox from "components/popupBox";

export default function QueueEntry() {
  const { id } = useParams();
  const { userContext } = useContext(UserContext);
  const { userId, username /*, permissions*/ } = userContext;

  const [isLoading, setIsLoading] = useState(false);
  const [queueData, setQueueData] = useState(null);
  const [comments, setComments] = useState([]);
  const [disableStatus, setDisableStatus] = useState(false);
  const [status, setStatus] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const { notifications, removeNotification } = useContext(WebSocketContext);

  useEffect(() => {
    queueRequest();
  }, []);

  useEffect(() => {
    if (Object.keys(notifications[websocketKeyAlert]).length === 0) {
      return;
    }

    if (!queueData) {
      return;
    }

    const alert = notifications[websocketKeyAlert][0];

    alert.entries.forEach((x) => {
      if (x.queueEntry.entityId === queueData.queueEntry.entityId) {
        setModalOpen(true);
      }
    });

    removeNotification(websocketKeyAlert);
  }, [notifications]);

  const queueRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setQueueData(resp);
        setStatus(resp.status);

        if (resp.status !== "pending") {
          setDisableStatus(true);
        }

        if (resp.comments) {
          setComments(resp.comments);
        } else {
          setComments([]);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const queueUpdateRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
      status: status,
    };

    fetchPost(QueueBackendPath + UpdateBackendPostfix, body)
      .then((resp) => {
        setDisableStatus(true);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cardClass = "lg:w-1/2 w-full";
  const margin = "ml-1 mb-3";

  const queueDescription = () => {
    if (!queueData) {
      return;
    }

    const overviewColumns = [
      {
        label: "Action",
        field: <Pill text={queueData.action} color={queueActionColorTable[queueData.action]} />,
      },
      {
        label: "Submitter",
        field: queueData.submitterUsername,
      },
      {
        label: "Created Time",
        field: queueData.createdDateTime ? <Time time={queueData.createdDateTime} /> : null,
      },
    ];

    return (
      <Card className={margin} title={"About"}>
        <Heading>{queueData.title}</Heading>
        <div className="max-h-80 overflow-y-auto">
          <div className="flex justify-evenly">
            {overviewColumns.map((c, i) => {
              return (
                <div key={`overviewCard-${i}`} className="mr-3 ml-3">
                  <div className="font-bold text-lg mb-2">{c.label}:</div>
                  <div>{c.field ? c.field : "N/A"}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center space-x-4 mt-4">
          {!queueData.queueEntry.data || (
            <Link className="text-sm" to={SubmissionFrontendPath + "/" + queueData.queueEntry.entityType + "?queueId=" + id}>
              <Button className={"rounded-lg"}>Edit</Button>
            </Link>
          )}
          {(queueData.action === "insert" && queueData.status !== "allow") || (
            <Link
              className="text-sm"
              to={DatabaseFrontendPath + "/" + queueData.queueEntry.entityType + EntryPostfix + queueData.queueEntry.entityId}
            >
              <Button className={"rounded-lg"}>View Original</Button>
            </Link>
          )}
        </div>
      </Card>
    );
  };

  const historyComment = () => {
    if (!queueData) {
      return;
    }

    if (!queueData.queueEntry.historyComment) {
      return;
    }

    return (
      <Card className={margin} title={"History Comment"}>
        <div className="max-h-80 overflow-y-auto">{queueData.queueEntry.historyComment}</div>
      </Card>
    );
  };

  const commentCard = () => {
    const onAdd = async (newComment, parentId) => {
      const body = {
        parentId: parentId,
        queueId: id,
        text: newComment,
      };

      try {
        const { id } = await fetchPost(QueueBackendPath + CommentPostfix + CreateBackendPostfix, body);
        toast.success("Comment successfully added.");
        return { id };
      } catch (resp) {
        toast.error(resp.message);
        throw new Error(resp.message);
      }
    };

    const onDelete = async (commentId) => {
      const body = {
        commentId: commentId,
        queueId: id,
      };

      try {
        await fetchPost(QueueBackendPath + CommentPostfix + DeleteBackendPostfix, body);
        toast.success("Comment successfully deleted.");
      } catch (resp) {
        toast.error(resp.message);
        throw new Error(resp.message);
      }
    };

    const onEdit = async (commentId, text) => {
      const body = {
        commentId: commentId,
        queueId: id,
        text: text,
      };

      try {
        await fetchPost(QueueBackendPath + CommentPostfix + UpdateBackendPostfix, body);
        toast.success("Comment successfully updated.");
      } catch (resp) {
        toast.error(resp.message);
        throw new Error(resp.message);
      }
    };

    if (!queueData) {
      return;
    }

    return (
      <Card className={margin} title={"Comments"}>
        <div className="text-left">
          <CommentSection
            userId={userId}
            username={username}
            comments={comments}
            setComments={setComments}
            onAdd={onAdd}
            onReply={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </div>
      </Card>
    );
  };

  const judgmentCard = () => {
    if (!queueData) {
      return;
    }

    const consensusColumns = [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Reject",
        value: "reject",
      },
      {
        label: "Allow",
        value: "allow",
      },
    ];

    return (
      <Card className={margin} title={"Judgement"}>
        <div className="flex justify-between">
          <div className="flex justify-center">
            <RadioButtons
              className="mb-3"
              keyPrefix="alertDetails"
              onChange={(x) => setStatus(x)}
              properties={consensusColumns}
              checkedVariable={status}
              disabled={disableStatus}
            />
          </div>

          <div className="flex justify-end space-x-4">
            <Button onClick={queueUpdateRequest} className={"rounded-lg"} disabled={disableStatus}>
              Confirm
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />

      <Title>{"Queue Entry"}</Title>
      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {queueDescription()}
          {historyComment()}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {judgmentCard()}
          {<HistoryCard payload={queueData} />}
        </div>
      </div>
      <div className="lg:flex"></div>
      <div className="lg:flex">
        <div className={`w-full ${margin}`}>{commentCard()}</div>
      </div>

      <PopupBox title={"Notice"} onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        Queue content updated. Please refresh the page.
      </PopupBox>
    </>
  );
}

/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Login from "./login";
import Registration from "./registration1";
import Logout from "./logout";
import Registration2 from "./registration2";
import UserSearch from "./grid";
import UserEntry from "./entry";
import UserEdit from "./edit";
import Mfa from "./mfa";
import Reset1 from "./reset1";
import Reset2 from "./reset2";

/* CONSTANTS */
import {
  RegistrationFrontendPath,
  LoginFrontendPath,
  LogoutFrontendPath,
  EntryPostfix,
  UpdatePostfix,
  MfaFrontendPath,
  SearchPostfix,
  ResetFrontendPath,
} from "constants/routing/frontend";

export default function SubmissionRoutes() {
  const hiddenLinks = [
    {
      pathPart: LoginFrontendPath,
      element: <Login />,
    },
    {
      pathPart: SearchPostfix,
      element: <UserSearch />,
    },
    {
      pathPart: MfaFrontendPath,
      element: <Mfa />,
    },
    {
      pathPart: EntryPostfix + ":id",
      element: <UserEntry />,
    },
    {
      pathPart: RegistrationFrontendPath,
      element: <Registration />,
    },
    {
      pathPart: RegistrationFrontendPath + "/:jwtToken",
      element: <Registration2 />,
    },
    {
      pathPart: LogoutFrontendPath,
      element: <Logout />,
    },
    {
      pathPart: ResetFrontendPath,
      element: <Reset1 />,
    },
    {
      pathPart: ResetFrontendPath + "/:jwtToken",
      element: <Reset2 />,
    },
    {
      pathPart: UpdatePostfix + "/:id",
      element: <UserEdit />,
    },
  ];

  return (
    <>
      <SeoHeader pageTitle={"User"} />

      <Routes>
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return <Route key={`user-${i}`} path={pathPart} exact element={element} />;
        })}
      </Routes>
    </>
  );
}

/** LIBS */
import React, { useState, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

/* CUSTOMS */
import MultiSelect from "components/multiSelect";
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Card from "components/card";
import { ToLabelValueFromMap, ToLabelValueFromSource } from "lib/strings";
import { fetchPost } from "lib/fetch";
import CheckBox from "components/checkBox";

/* CONSTANTS */
import Button from "components/button";
import Loader from "components/loader";
import TextBox from "components/textBox";
import { CreateBackendPostfix, ForumBackendPath, GetBackendPostfix, UpdateBackendPostfix } from "constants/routing/backend";
import { ForumFrontendPath } from "constants/routing/frontend";
import CommentEditBox from "./commentEditBox";

/* ICONS */

/* SERVICES */
import { UserContext } from "context/user";
import { PermissionAMod } from "constants/permissions";

export default function TopicUpsert() {
  const { userContext } = useContext(UserContext);
  const { isLoggedIn, permissions } = userContext;

  const [isLoading, setIsLoading] = useState(false);
  const [postText, setPostText] = useState("");

  const [topicTitle, setTopicTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [subTags, setSubTags] = useState([]);
  const [subForumList, setSubForumList] = useState(null);
  const [isLocked, setIsLocked] = useState(null);
  const [isPinned, setIsPinned] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tag = queryParams.get("tag");
  const topicId = queryParams.get("topicId");
  const postBoxRef = useRef(null);


  var titleListAggregated =
    subForumList &&
    subForumList.reduce((acc, curr) => {
      acc[curr.tag] = curr.title;
      return acc;
    }, {});

  const navigate = useNavigate();

  useEffect(() => {
    if (topicId) {
      topicRequest();
    }

    if (tag) {
      setTags([tag]);
    }

    subForumRequest();
  }, []);

  const initialForumPostCreate = () => {
    setIsLoading(true);

    const body = {
      id: topicId,
      title: topicTitle,

      tags: tags,
      subTags: subTags,

      isPinned: isPinned,
      isLocked: isLocked,

      initialPostText: postText,
    };

    const postFix = isEdit ? UpdateBackendPostfix : CreateBackendPostfix;

    fetchPost(ForumBackendPath + "/topic" + postFix, body)
      .then(async (resp) => {
        var idToDirectTo = "";
        if (topicId) {
          idToDirectTo = topicId;
        } else {
          idToDirectTo = resp.id;
        }

        navigate(`${ForumFrontendPath}/topic/${idToDirectTo}`);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const subForumRequest = () => {
    setIsLoading(true);

    fetchPost(ForumBackendPath + "/subForum", null)
      .then((resp) => {
        setSubForumList(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const topicRequest = () => {
    setIsLoading(true);

    fetchPost(ForumBackendPath + "/topic" + GetBackendPostfix, { id: topicId })
      .then((resp) => {
        setTags(resp.tags);
        setSubTags(resp.subTags);
        setTopicTitle(resp.title);
        setIsLocked(resp.isLocked);
        setIsPinned(resp.isPinned);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (tag ^ topicId) {
    return;
  }

  if (!isLoggedIn) {
    return;
  }

  const isEdit = topicId;
  const canDoModActions = permissions.includes(PermissionAMod);

  return (
    <div className="space-y-2 mb-2">
      <SeoHeader pageTitle={`Forum - ${isEdit ? "Edit" : "Create"} Topic`} />
      <Title>{isEdit ? "Edit Topic" : "Create Topic"}</Title>
      <Loader isLoading={isLoading} />

      <Card title="Topic Information">
        <div className="grid grid-cols-12 space-y-4">
          <TextBox
            id={"topicTitle"}
            className="col-span-12 w-full"
            placeholder={"Topic Title"}
            value={topicTitle}
            onChange={setTopicTitle}
          />

          <MultiSelect
            className="col-span-12"
            placeholder="Tag / Sub-Forum"
            isMulti={false}
            onSelect={(e) => {
              setTags(e.value);
            }}
            options={ToLabelValueFromMap(titleListAggregated)}
            value={ToLabelValueFromSource(tags, ToLabelValueFromMap(titleListAggregated))}
            onInputChange={() => {}}
          />

          <MultiSelect
            className="max-lg:col-span-12 md:col-span-12"
            placeholder="Sub-Tags"
            isMulti={true}
            onCreate={(e) => {
              setSubTags(e.map((x) => x.label));
            }}
            onRemove={(_, meta) => {
              const temp = [...subTags];
              const index = temp.indexOf(meta.removedValue.value);
              temp.splice(index, 1);
              setSubTags(temp);
            }}
            value={ToLabelValueFromMap(subTags)}
            onInputChange={() => {}}
          />
        </div>
        <div className="col-span-12 w-full space-y-3 space-x-3">
          <CheckBox checked={isLocked} onChange={setIsLocked} label={"Is Locked"} disabled={!canDoModActions} />
          <CheckBox checked={isPinned} onChange={setIsPinned} label={"Is Pinned"} disabled={!canDoModActions} />
        </div>
      </Card>

      {isEdit ? null : (
        <Card title="Starting Post">
          <CommentEditBox ref={postBoxRef} className={"my-2"} postText={postText} setPostText={setPostText} />
        </Card>
      )}

      <div className="flex items-center justify-end">
        <Button className="rounded-lg" onClick={initialForumPostCreate}>
          {isEdit ? "Edit" : "Post"}
        </Button>
      </div>
    </div>
  );
}

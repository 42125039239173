/** LIBS */
import React, { useState } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import Title from "components/title";
import TextBox from "components/textBox";
import Button from "components/button";
import Card from "components/card";

/* CONSTANTS */
import { UpdateBackendPostfix, UserBackendPath } from "constants/routing/backend";

export default function Reset1() {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const passwordRequest = () => {
    setIsLoading(true);

    const body = {
      username: username,
    };

    fetchPost(UserBackendPath + "/guest" + UpdateBackendPostfix + "/password1", body)
      .then((d) => {
        toast.success(d.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SeoHeader pageTitle={"Search"} />
      <Loader isLoading={isLoading} />

      <Title>Reset</Title>

      <Card title="Form">
        If you&apos;ve forgotten your login details, don&apos;t worry, they can be retrieved. To start this process please enter your
        username below.
        <div className="col-span-12 flex justify-center mt-5">
          <TextBox id={"username-textBox"} className={"mb-3 w-full max-w-xs"} placeholder={"Username"} onChange={setUsername} />
        </div>
        <div className="flex justify-end space-x-4 mt-4">
          <Button className="mt-3 rounded-lg" onClick={passwordRequest}>
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
}

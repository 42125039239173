/** LIBS */
import React, { useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Heading from "components/heading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* CUSTOMS */
import Title from "components/title";
import PopupBox from "components/popupBox";
import CheckBox from "components/checkBox";
import Button from "components/button";
import { SubmissionList } from "../submit";

/* SERVICES */
import { UserContext } from "context/user";
import Card from "components/card";

export default function Submissions() {
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [moduleSelected, setModuleSelected] = useState("");

  const navigate = useNavigate();

  const { userContext } = useContext(UserContext);
  const { isLoggedIn } = userContext;

  const ConfirmationModal = () => {
    return(
      <PopupBox
        title={"Submission Notice"}
        onClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
        className="text-contrastChangeText"
        scrollOverflow={true}
      >
        <div className="text-left">
          You&apos;re sharing content with the world. Let&apos;s make sure it&apos;s yours to
          share. Pick the section that best applies and only tick the boxes if
          you understand and agree.
        </div>
        <Heading>I&apos;m submitting...</Heading>
        <div className="grid grid-flow-row-dense md:grid-cols-2">
          <div className="col-span-1 m-4">
            <h1 className="font-bold text-lg">My work or group&apos;s work</h1>
            <div className="text-left">
              <div>
                Sharing can get complicated. Sometimes people change their
                minds. RHDI only hosts content that is free to distribute.
              </div>

              <ul className="list-disc m-3">
                <li>
                  You give RHDI permission to host this work. This permission
                  can not be revoked. If you&apos;re part of a group, the entire
                  group must agree.
                </li>
                <li>
                  If your work includes a license, that license must be
                  compatible with RHDI policy. If your work does not include a
                  license that&apos;s fine too.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-1 m-4">
            <h1 className="font-bold text-lg">Someone else&apos;s work</h1>
            <div className="text-left">
              <div>
                Make sure it&apos;s okay for you to submit this work. You don&apos;t just
                need permission to share, you need permission to <i>publish</i>.
              </div>

              <ul className="list-disc m-3">
                <li>
                  Ideally an author will submit their own work. Only submit work
                  if the author can&apos;t or prefers you do so on their behalf.
                </li>
                <li>
                  You have express permission to publish this work or it has a
                  license that allows RHDI to distribute it freely.
                </li>
                <li>
                  If the work includes a license, that license must be
                  compatible with RHDI policy. If the work does not include a
                  license, that&apos;s fine too.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <CheckBox
          className="mb-6"
          checked={isChecked}
          onChange={setIsChecked}

          label="I am authorized to submit this work."
        />

        <div>
          <Button disabled={!isChecked} className="rounded-md" onClick={() => {
            navigate(moduleSelected);
          }}>
            Next
          </Button>
        </div>
      </PopupBox>
    );
  };

  if (!isLoggedIn){
    toast.error("You must be logged in to submit.");
    return;
  }

  return (
    <>
      <Title>Submit to the Archive</Title>
      <Card title={"Notice"} className={"m-3"}>
        Make sure content you&apos;re submitting is not already hosted on RHDI! If
        you need to update an existing page, please use the edit button on that
        page.
      </Card>
      <Routes>
        {SubmissionList.map(({ pathPart, element }, i) => {
          return (
            <>

              <Route
                key={`submissionRoute-${i}`}
                path={pathPart}
                exact
                element={element}
              />
            </>
          );
        })}
      </Routes>

      {ConfirmationModal()}

      <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {SubmissionList.map(({ icon, text, path, showSharingWarning }, i) => {
          const iconClassName = "block mb-1 inline-block mt-0 mr-1 w-32 pixelImage";

          const props = { ...icon.props, className: iconClassName};
          let icon1 = React.cloneElement(icon, props);

          return (
            <div 
              key={`submission-${i}`} 
              title={text}
              className="col-span-1 bg-primaryBlue hover:bg-primaryBlueHover rounded-xl m-3 cursor-pointer">
              <div
                className="p-9"
                onClick={() => {
                  if (showSharingWarning){
                    setShowModal(true);
                    setModuleSelected(path);
                  } else {
                    navigate(path);
                  }
                }}
              >
                {icon1}
                <p className="mt-5 text-3xl truncate text-white select-none font-gameFont retro-text-shadow">
                  {text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

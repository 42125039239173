import React from "react";
import PropTypes from "prop-types";

export default function ButtonGroupTabbed({ properties, id }) {
  return (

    <div className="flex justify-center mb-5">
      <div className="flex items-center justify-evenly mb-5 overflow-hidden rounded-b-lg md:w-3/4 shadow-lg">
        {properties.map(({ onClick, text, disabled, title }, i) => {
          return (
            <span
              key={`${id}-${i}`}
              onClick={onClick}
              disabled={disabled}
              title={title || text}
              className={`p-2 bg-primaryBlue hover:bg-primaryBlueHover 
            ${
            disabled ? "opacity-50" : "cursor-pointer"
            } w-full h-full text-sm select-none`}
            >
              {text}
            </span>
          );
        })}
      </div>
    </div>
  );
}

ButtonGroupTabbed.propTypes = {
  id: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
};

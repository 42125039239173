/** LIBS */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import SeoHeader from "components/seoHeader";
import Time from "components/time";
import { FriendlyColumns } from "components/friendlyColumns";
import { requestFormatFilter } from "lib/elastic";
import Paper from "components/paper";
import Paginator from "components/paginator";
import InvisibleScroll from "components/invisibleScroll";
import { ForumFrontendPath, UpdatePostfix, UserFrontendPath } from "constants/routing/frontend";
import { sleepSeconds } from "lib/sleep";
import Button from "components/button";

/* CONSTANTS */
import {
  ForumBackendPath,
  GetBackendPostfix,
  HistoryMetricBackendPath,
  MetadataBackendPostfix,
  SearchBackendPostfix,
  UserBackendPath,
} from "constants/routing/backend";
import { removeMarkdownFormatting } from "lib/strings";
import { pageSize } from "constants/models";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";
import { SortableTable } from "components/sortableTable";

/* SERVICES */
import { UserContext } from "context/user";
import { PermissionManageUser } from "constants/permissions";

export default function UserEntry() {
  const sortFieldKey = "createdDate";
  const sortDirectionKey = "desc";

  const [payloadUser, setPayloadUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { userId, permissions } = userContext;

  useEffect(() => {
    userGetRequest();
  }, [id]);

  useEffect(() => {
    console.log({userId});
  }, [userId]);

  const locatePostToScroll = (postId) => {
    const body = {
      id: postId,
      sortField: sortFieldKey,
      sortDirection: sortDirectionKey,
    };

    fetchPost(ForumBackendPath + "/post" + "/locate", body)
      .then((resp) => {
        navigate(`${ForumFrontendPath}${"/topic"}/${resp.topicId}/?${new URLSearchParams({ postId })}`);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(UserBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadUser(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const authorDescription = () => {
    if (!payloadUser) {
      return;
    }

    const headings = [
      {
        label: "Permissions",
        field: "permissions",
        width: "col-start-1 col-span-12",
        dataType: "pills",
      },
    ];

    return (
      <Card className={margin} title={"About"}>
        {!payloadUser.thumbnailUrl || <div className="flex items-center justify-center">
          <img src={payloadUser.thumbnailUrl.url} alt=""/>
        </div>}
        <div className="grid grid-cols-12 gap-1 my-3 w-full">
          <FriendlyColumns data={payloadUser} columns={headings} keyPrefix={""} />
        </div>
      </Card>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  const forumPosts = () => {
    const [payload, setPayload] = useState(null);
    const [page, setPage] = useState(0);
    const [sortField] = useState(sortFieldKey);
    const [sortDirection] = useState(sortDirectionKey);
    const gameMultiSelectRef = useRef(null);

    const payloadRequest = () => {
      setIsLoading(true);

      let matchFilter = requestFormatFilter([
        {
          field: "createdByUserId",
          operator: "must",
          value: id,
        },
      ]);

      const body = {
        page: page,
        sortField: sortField,
        sortDirection: sortDirection,
        filters: matchFilter,
      };

      fetchPost(ForumBackendPath + "/post" + SearchBackendPostfix, body)
        .then((resp) => {
          setPayload(resp);
        })
        .catch((resp) => {
          toast.error(resp.message);
          setPayload(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const paginationWrapper = (autoScroll, allowInputChange) => {
      if (!payload || !payload.entries) {
        return;
      }

      const onChange = async () => {
        if (autoScroll) {
          await sleepSeconds(0.5);
          gameMultiSelectRef.current.jumpToSection();
        }
      };

      return (
        <Paginator
          page={page}
          setPage={setPage}
          currentPageSize={payload.entries && payload.entries.length}
          pageWindowSize={pageSize}
          totalEntries={payload && payload.total}
          allowInputChange={allowInputChange}
          onChange={onChange}
        />
      );
    };

    useEffect(() => {
      payloadRequest();
    }, [page, sortField, sortDirection]);

    if (!payload) {
      return;
    }

    if (!payload.entries) {
      return;
    }

    return (
      <>
        <Title>Forum Posts</Title>
        <InvisibleScroll ref={gameMultiSelectRef} />

        <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>

        {payload.entries.map((x, i) => {
          return (
            <Paper key={`post-${i}`} className={"my-2 text-left"}>
              <div className="flex justify-between">
                <div className="text-xs mb-1 flex justify-start">
                  Posted <Time className="ml-1" time={x.createdDate} fromNow />
                </div>
                <div className="text-xs mb-1">
                  {x.updatedDate === 0 || (
                    <div className="text-xs mb-1 flex justify-start italic">
                      Last Edit: <Time className="mr-1 ml-1" time={x.updatedDate} fromNow />
                      {" by "}
                      <Link className="ml-1">{x.updatedBy}</Link>
                    </div>
                  )}
                </div>
              </div>

              <Markdown
                components={{
                  a: ({ href, children }) => {
                    // Check if the link is internal (starts with "/")
                    if (href && (href.startsWith("/") || href.startsWith("?"))) {
                      return (
                        <div
                          className="linkStyle"
                          onClick={() => {
                            const postId = href.replace("?postId=", "");
                            locatePostToScroll(postId);
                          }}
                        >
                          {children}
                        </div>
                      );
                    }

                    // If it's an external link, use the default <a> behavior
                    return (
                      <a href={href} target="_blank" rel="noopener noreferrer">
                        {children}
                      </a>
                    );
                  },
                }}
              >
                {x.text}
              </Markdown>
              <div className="flex justify-end space-x-2">
                <Link className="text-sm" to={`${ForumFrontendPath}${"/topic"}/${x.topicId}/?${new URLSearchParams({ postId: x.id })}`}>
                  <Button className="rounded-lg">
                    <div className="flex items-center justify-center">
                      <GotoIcon className="mr-2" /> Goto
                    </div>
                  </Button>
                </Link>
              </div>
            </Paper>
          );
        })}

        <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
      </>
    );
  };

  const historyGrid = () => {
    const [page, setPage] = useState(0);
    const [sortField, setSortField] = useState("history.time");
    const [sortDirection, setSortDirection] = useState("desc");
    const [metadata, setMetadata] = useState(null);
    const [payload, setPayload] = useState(null);
    const gameMultiSelectRef = useRef(null);

    const columns = [
      {
        label: "Time",
        field: "history.time",
        width: "w-10 max-md:hidden",
        dataType: "dateTime",
      },
      {
        label: "Entity ",
        field: "entityType",
        width: "w-5 max-md:hidden",
        dataType: "write",
      },
      {
        label: "Title",
        idField: "entityId",
        field: "entityTitle",
        width: "w-20",
        dataType: "link",
        to: (data) => {
          return `/database/${data.entityType}/entry/`;
        },
      },
      {
        label: "Message",
        field: "history.message",
        width: "w-40",
        dataType: "write",
      },
    ];

    useEffect(() => {
      historyRequest();
    }, [page, sortField, sortDirection]);

    useEffect(() => {
      fetchPost(HistoryMetricBackendPath + MetadataBackendPostfix, null)
        .then((resp) => {
          setMetadata(resp);
        })
        .catch((resp) => {
          toast.error(resp.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    const historyRequest = () => {
      setIsLoading(true);

      let matchFilter = requestFormatFilter([
        {
          field: "history.userId",
          operator: "must",
          value: id,
        }
      ]);

      const body = {
        page: page,
        sortField: sortField,
        sortDirection: sortDirection,
        filters: matchFilter,
      };

      fetchPost(HistoryMetricBackendPath + SearchBackendPostfix, body)
        .then((resp) => {
          setPayload(resp);
        })
        .catch((resp) => {
          toast.error(resp.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const paginationWrapper = (autoScroll, allowInputChange) => {
      if (!payload || !payload.entries) {
        return;
      }

      const onChange = async () => {
        if (autoScroll) {
          await sleepSeconds(0.5);
          gameMultiSelectRef.current.jumpToSection();
        }
      };

      return (
        <Paginator
          page={page}
          setPage={setPage}
          currentPageSize={payload.entries && payload.entries.length}
          pageWindowSize={pageSize}
          totalEntries={payload && payload.total}
          allowInputChange={allowInputChange}
          onChange={onChange}
        />
      );
    };

    if (!payload) {
      return;
    }

    if (!payload.entries) {
      return;
    }

    if (payload.total === 0) {
      return;
    }

    return (
      <>
        <Title>Contributions</Title>
        <InvisibleScroll ref={gameMultiSelectRef} />

        <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>

        <SortableTable
          data={payload && payload.entries}
          metadataColumn={metadata && metadata.columns}
          columns={columns}
          setSortField={setSortField}
          setSortDirection={setSortDirection}
          sortField={sortField}
          sortDirection={sortDirection}
        />

        <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
      </>
    );
  };

  const isSelf = id === userId;
  const isManageUser = permissions.includes(PermissionManageUser);

  return (
    <>
      {payloadUser ? (
        <SeoHeader
          pageTitle={"Users"}
          pageSectionTitle={payloadUser.username}
          description={removeMarkdownFormatting(payloadUser.username)}
        />
      ) : null}

      <Title>{payloadUser && payloadUser.username}</Title>

      <Loader isLoading={isLoading} />

      <div className="flex justify-end">
        {(isSelf || isManageUser)?(<Link to={`${UserFrontendPath}${UpdatePostfix}/${id}`}>
          <Button className="rounded-lg">
              Edit
          </Button>
        </Link>): null }
      </div>

      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>{authorDescription()}</div>
        <div className={`${cardClass} ${margin}`}></div>
      </div>

      {historyGrid()}
      {forumPosts()}
    </>
  );
}

/** LIBS */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import { Link } from "react-router-dom";
import Paginator from "components/paginator";
import { fetchPost } from "lib/fetch";
import { toast } from "react-toastify";
import { requestFormatFilter } from "lib/elastic";
import Loader from "components/loader";
import PopupBox from "components/popupBox";
import Card from "components/card";
import { FriendlyColumns } from "components/friendlyColumns";
import InvisibleScroll from "components/invisibleScroll";
import { sleepSeconds } from "lib/sleep";

/* CONSTANTS */
import { pageSize } from "constants/models";
import { PermissionAMod } from "constants/permissions";
import { CreatePostfix, EntryPostfix, ForumFrontendPath, UpdatePostfix, UserFrontendPath } from "constants/routing/frontend";
import {
  BackendEndpoint,
  DeleteBackendPostfix,
  ForumBackendPath,
  FriendlyBackendPostfix,
  GetBackendPostfix,
  NewsBackendPath,
  SearchBackendPostfix,
} from "constants/routing/backend";

/* ICONS */
import Button from "components/button";
import CommentEditBox from "./commentEditBox";

/* SERVICES */
import { UserContext } from "context/user";
import CommentEntry from "./commentEntry";
import NewsCard from "../search/news/newsCard";

export default function TopicEntry() {
  const sortFieldKey = "createdDate";
  const sortDirectionKey = "asc";

  const [payloadTopic, setPayloadTopic] = useState(null);
  const [payload, setPayload] = useState(null);
  const [payloadLinkedEntity, setPayloadLinkedEntity] = useState(null);

  const [page, setPage] = useState(0);

  const [sortField] = useState(sortFieldKey);
  const [sortDirection] = useState(sortDirectionKey);
  const [isLoading, setIsLoading] = useState(false);

  const [postText, setPostText] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(() => {});
  const [modalText, setModalText] = useState("");

  const navigate = useNavigate();

  const { id } = useParams();

  const [topicId, setTopicId] = useState(id);

  const { userContext } = useContext(UserContext);
  const { username, permissions } = userContext;

  const [scrollToId, setScrollToId] = useState("");
  const [highlightPostId, setHighlightPostId] = useState("");

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const postIdQuery = queryParams.get("postId");

  const postBoxRef = useRef(null);

  useEffect(() => {
    setTopicId(id);
  }, [id]);

  useEffect(() => {
    if (!payloadTopic) {
      return;
    }

    newsRequest();
  }, [payloadTopic]);

  const newsRequest = () => {
    if (!payloadTopic.linkedEntityType) {
      return;
    }

    const body = {
      id: payloadTopic.linkedEntityId,
    };

    fetchPost(BackendEndpoint + "/" + payloadTopic.linkedEntityType + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadLinkedEntity(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const locatePostToScroll = (postId) => {
    const body = {
      id: postId,
      sortField: sortFieldKey,
      sortDirection: sortDirectionKey,
    };

    fetchPost(ForumBackendPath + "/post" + "/locate", body)
      .then((resp) => {
        if (resp.topicId === topicId) {
          // window.history.pushState(null, "", `${ForumFrontendPath}${"/topic"}/${resp.topicId}`);
        } else {
          navigate(`${ForumFrontendPath}${"/topic"}/${resp.topicId}`);
        }

        setTopicId(resp.topicId);
        setScrollToId(postId);
        setPage(resp.pageNumber);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!postIdQuery) {
      return;
    }

    locatePostToScroll(postIdQuery);
  }, [postIdQuery]);

  useEffect(() => {
    topicGetRequest();
  }, [topicId]);

  useEffect(() => {
    scrollToPost();
  }, [topicId, payload, page, scrollToId, isLoading]);

  useEffect(() => {
    postsSearchRequest();
  }, [topicId, page, sortField, sortDirection]);

  const scrollToPost = async () => {
    if (!scrollToId) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!payload) {
      return;
    }

    await sleepSeconds(0.5);

    const temp = document.querySelector(`#${scrollToId}`);

    if (!temp) {
      setScrollToId("");
      return;
    }

    temp.scrollIntoView({ behavior: "smooth" });
    setScrollToId("");
    setHighlightPostId(scrollToId);
  };

  const topicGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: topicId,
    };

    fetchPost(ForumBackendPath + "/topic" + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadTopic(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postsSearchRequest = async () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "topicId",
        operator: "must",
        value: topicId,
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ForumBackendPath + "/post" + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createPost = () => {
    setIsLoading(true);

    const body = {
      topicId: topicId,
      text: postText,
    };

    fetchPost(ForumBackendPath + "/post" + CreatePostfix, body)
      .then(async (resp) => {
        toast.success("Created post.");

        await postsSearchRequest();
        payloadTopic.posts += 1;

        if (postBoxRef.current) {
          postBoxRef.current.clear();
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteTopicRequest = () => {
    setIsLoading(true);

    const body = {
      id: topicId,
    };

    fetchPost(ForumBackendPath + "/topic" + DeleteBackendPostfix, body)
      .then((resp) => {
        navigate(ForumFrontendPath);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deletePostRequest = (postId) => {
    setIsLoading(true);

    const body = {
      id: postId,
    };

    fetchPost(ForumBackendPath + "/post" + DeleteBackendPostfix, body)
      .then(async (resp) => {
        toast.success("Deleted post.");

        await postsSearchRequest();
        payloadTopic.posts -= 1;
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);
  const commentTextboxScrollRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload || !payload.entries) {
      return;
    }

    const onChange = async () => {
      if (autoScroll) {
        await sleepSeconds(0.5);
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  const headings = [
    {
      label: "Created By",
      field: "createdByUsername",
      idField: "createdByUserId",
      width: "col-span-4 lg:col-span-2",
      dataType: "link",
      to: `${UserFrontendPath}${EntryPostfix}`,
    },
    {
      label: "Creation Time",
      field: "createdDate",
      width: "col-span-4 lg:col-span-2",
      dataType: "dateTime",
    },
    {
      label: "Views",
      field: "views",
      width: "col-span-4 lg:col-span-2",
      dataType: "write",
    },
    {
      label: "Posts",
      field: "posts",
      width: "col-span-4 lg:col-span-2",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-start-1 col-span-6",
      dataType: "pills",
    },
    {
      label: "Sub-Tags",
      field: "subTags",
      width: "col-span-6",
      dataType: "pills",
    },
  ];

  const canDelete = permissions.includes(PermissionAMod);
  const canEditTopic = permissions.includes(PermissionAMod) || (payloadTopic && payloadTopic.username === username);
  const canPost = username && (permissions.includes(PermissionAMod) || (payloadTopic && !payloadTopic.isLocked));

  if (!payloadTopic) {
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={`Forum - ${payloadTopic.title}`} />
      <Title>Topic</Title>

      <Loader isLoading={isLoading} />

      <PopupBox title={"Warning"} onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        <div className="mb-3">{modalText}</div>
        <div className="flex items-center justify-end space-x-3">
          <Button className="rounded-lg" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            className="rounded-lg"
            onClick={() => {
              setModalOpen(false);
              modalAction();
            }}
            secondary={true}
          >
            Confirm
          </Button>
        </div>
      </PopupBox>

      {payloadLinkedEntity ? (
        <NewsCard className="mb-2" payload={payloadLinkedEntity} id={payloadTopic.linkedEntityId} hideDiscussionButton={true} />
      ) : null}

      <Card title={payloadTopic.title} className={"mb-3"}>
        <div className="grid grid-cols-12 gap-1 my-3 w-full">
          <FriendlyColumns data={payloadTopic} columns={headings} keyPrefix={""} />
        </div>
        <div className="flex items-center justify-end">
          {canEditTopic && (
            <Link to={ForumFrontendPath + "/topic" + UpdatePostfix + `/?${new URLSearchParams({ topicId: topicId })}`}>
              <Button className="rounded-lg mr-2">Edit</Button>
            </Link>
          )}
          {canDelete && (
            <Button
              secondary={true}
              className="rounded-lg"
              onClick={() => {
                setModalText("Are you sure you want to delete this topic? This action cannot be undone.");
                setModalAction(() => deleteTopicRequest);
                setModalOpen(true);
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </Card>

      <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>

      <InvisibleScroll ref={gameMultiSelectRef} />

      {payload &&
        payload.entries &&
        payload.entries.map((_, i) => {
          return (
            <CommentEntry
              topicId={topicId}
              payload={payload}
              setPayload={setPayload}
              i={i}
              payloadTopic={payloadTopic}
              postText={postText}
              setPostText={setPostText}
              setModalText={setModalText}
              setModalAction={setModalAction}
              setModalOpen={setModalOpen}
              locatePostToScroll={locatePostToScroll}
              deletePostRequest={deletePostRequest}
              highlightPostId={highlightPostId}
              postBoxRef={postBoxRef}
              commentTextboxScrollRef={commentTextboxScrollRef}
              key={`commentEntry-${i}`}
            />
          );
        })}

      <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>

      <InvisibleScroll ref={commentTextboxScrollRef} />

      {canPost && (
        <Card title="Create post" className={"mb-2"}>
          <CommentEditBox ref={postBoxRef} className={"my-2"} postText={postText} setPostText={setPostText} title="Add Post" />

          <div className="flex justify-end">
            <Button className="rounded-lg" onClick={createPost}>
              Post
            </Button>
          </div>
        </Card>
      )}
    </>
  );
}

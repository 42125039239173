/* LIBS */
import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { toast } from "react-toastify";

/* CUSTOMS */
import Heading from "components/heading";
import PopupBox from "components/popupBox";

import Button from "components/button";

/* SERVICES */
import { UserContext } from "context/user";
import {
  DatabaseFrontendPath,
  EntryPostfix,
  ReviewFrontendPath,
  SubmissionFrontendPath,
  UserFrontendPath,
} from "constants/routing/frontend";
import { fetchPost } from "lib/fetch";
import { GetBackendPostfix, MetadataBackendPostfix, ReviewBackendPath, SearchBackendPostfix } from "constants/routing/backend";
import Loader from "components/loader";
import { filterOperatorMust, requestFormatFilter } from "lib/elastic";
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import InvisibleScroll from "components/invisibleScroll";
import { SortableTable } from "components/sortableTable";

export default function Review({ id, entityType }) {
  const [isLoading, setIsLoading] = useState(false);

  const { userContext } = useContext(UserContext);
  const { isLoggedIn } = userContext;

  const gameMultiSelectRef = useRef(null);

  const [payload, setPayload] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("createdDate");
  const [sortDirection, setSortDirection] = useState("desc");
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    fetchPost(ReviewBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection]);

  const hackRequest = () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "isDeleted",
        operator: filterOperatorMust,
        value: "false",
      },
      {
        field: "parentEntityId",
        operator: filterOperatorMust,
        value: id,
      },
      {
        field: "parentEntityType",
        operator: filterOperatorMust,
        value: entityType,
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ReviewBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const margin = "ml-1 mb-3";

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [reviewId, setReviewIndex] = useState("");
  const [reviewPayload, setReviewPayload] = useState(null);

  useEffect(() => {
    setReviewPayload(null);

    if (!reviewId){
      return;
    }

    const body = {
      id: reviewId,
    };

    fetchPost(ReviewBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setReviewPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setReviewPayload(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reviewId]);

  const modal = () => {
    if (!reviewPayload) {
      return;
    }

    return (
      <PopupBox
        title={reviewPayload.title}
        onClose={() => setFilterModalOpen(false)}
        isOpen={filterModalOpen}
        scrollOverflow={true}
      >
        <Heading>Review</Heading>
        <Markdown className="text-left mr-3 text-contrastChangeText">{reviewPayload.review}</Markdown>

        {reviewPayload.reviewOwnerResponse  && (
          <><Heading>Review Response</Heading>
            <Markdown className="text-left mr-3 text-contrastChangeText">{reviewPayload.reviewOwnerResponse}</Markdown>
          </>
        )}

        <div className="flex justify-end mt-3">
          <Link to={`${DatabaseFrontendPath}${ReviewFrontendPath}${EntryPostfix}${reviewId}`}>
            <Button className={"rounded-lg"}>
            More details
            </Button>
          </Link>
        </div>
      </PopupBox>
    );
  };

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return <></>;
    }

    if (payload.total <= pageSize) {
      return <></>;
    }

    const onChange = () => {
      if (autoScroll && gameMultiSelectRef.current) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        allowInputChange={allowInputChange}
        totalEntries={payload && payload.total}
        onChange={onChange}
      />
    );
  };

  const columns = [
    {
      label: "Title",
      field: "",
      width: "w-40",
      dataType: "custom",
      custom: (data) => {
        return(
          <div
            className="linkStyle"
            onClick={() => {
              if (data.id !== reviewId){
                setReviewPayload(null);
              }

              setFilterModalOpen(true);
              setReviewIndex(data.id);
            }}
          >
            {data.title}
          </div>
        );
      },
    },
    {
      label: "Username",
      field: "",
      width: "w-10 max-lg:hidden",
      dataType: "custom",
      custom: (data) => {
        if (!data.userId){
          return (<>{data.username}</>);
        }

        const to = `${UserFrontendPath}${EntryPostfix}${data.userId}`;
        
        return (<Link to={to}>{data.username}</Link>);
      },
    },
    {
      label: "Created Time",
      field: "createdDate",
      width: "w-5 max-md:hidden",
      dataType: "dateTime",
    },
    {
      label: "Version",
      field: "version",
      width: "w-5 max-md:hidden",
      dataType: "write",
    },
    {
      label: "Is Recommended",
      field: "isRecommended",
      width: "w-5 max-lg:hidden",
      dataType: "write",
    },
  ];

  return (
    <div className={margin}>
      {modal()}

      <Heading>Reviews</Heading>

      <InvisibleScroll ref={gameMultiSelectRef} />

      <div className="flex items-center justify-end">{paginationWrapper(false, false)}</div>

      {payload && (<SortableTable
        data={payload && payload.entries}
        metadataColumn={metadata && metadata.columns}
        columns={columns}
        setSortField={setSortField}
        setSortDirection={setSortDirection}
        sortField={sortField}
        sortDirection={sortDirection}
      />)}

      <div className="flex items-center justify-end">{paginationWrapper(true, true)}</div>
      
      {isLoggedIn ? (
        <div className="flex justify-end mr-5">
          <Link
            to={
              SubmissionFrontendPath +
              ReviewFrontendPath +
              `?${new URLSearchParams({ parentEntityId: id, parentEntityType: entityType })}`
            }
          >
            <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={() => {}}>
              Add Review
            </Button>
          </Link>
        </div>
      ) : null}

      <Loader isLoading={isLoading} />
    </div>
  );
}

Review.propTypes = {
  id: PropTypes.string,
  entityType: PropTypes.string,
};

import { AddHttpProtocol, AddHttpProtocolWs } from "lib/url";

const {
  REACT_APP_SERVER_SSL_ENABLE,
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PORT,
} = process.env;

/* 
Create the backend url
For local development, you can specify the host and port combination.

If left blank, it will be reached locally on the server. This is preferred for an nginx implementation e.g. in production.
*/
var backendHostPort = "";

if (REACT_APP_SERVER_HOST && REACT_APP_SERVER_PORT) {
  backendHostPort = `${REACT_APP_SERVER_HOST}:${REACT_APP_SERVER_PORT}/`;
} else {
  backendHostPort = window.location.hostname + "/";
}

export const WebsocketBackendPath = `${AddHttpProtocolWs(REACT_APP_SERVER_SSL_ENABLE)}${backendHostPort}api/ws`;

backendHostPort = `${AddHttpProtocol(REACT_APP_SERVER_SSL_ENABLE)}${backendHostPort}`;

backendHostPort = `${backendHostPort}api`;

export var BackendEndpoint = backendHostPort;

export const LogoutBackendPath = backendHostPort + "/logout";
export const LoginBackendPath = backendHostPort + "/login";
export const LoginMfaBackendPath = backendHostPort + "/mfa";
export const UserBackendPath = backendHostPort + "/user";
export const GameBackendPath = backendHostPort + "/game";
export const HistoryMetricBackendPath = backendHostPort + "/historyMetric";
export const ContentBackendPath = backendHostPort + "/content";
export const NewsBackendPath = backendHostPort + "/news";
export const CaptchaBackendPath = backendHostPort + "/captcha";
export const HashBackendPath = backendHostPort + "/hash";
export const AuthorBackendPath = backendHostPort + "/author";
export const PlatformBackendPath = backendHostPort + "/platform";
export const QueueBackendPath = backendHostPort +"/queue";
export const AdminBackendPath = backendHostPort + "/admin";
export const RegistrationBackendPath = backendHostPort + "/registration";
export const ForumBackendPath = backendHostPort + "/forum";
export const BlockListBackendPath = backendHostPort + "/blockList";
export const ReviewBackendPath = backendHostPort + "/review";

export const RegistrationBackendPostfix = "/registration";
export const SessionBackendPostfix = "/session";
export const UserBackendPostfix = "/user";
export const FriendlyBackendPostfix = "/friendly";
export const ActionBackendPostfix = "/action";
export const QueueBackendPostfix = "/queue";
export const CreateBackendPostfix = "/create";
export const UpdateBackendPostfix = "/update";
export const SearchBackendPostfix = "/search";
export const DeleteBackendPostfix = "/delete";
export const GetBackendPostfix = "/get";
export const GetPresignBackendPostfix = "/getPresignOnly";
export const MetadataBackendPostfix = "/metadata";
export const FieldValuesBackendPostfix = "/fieldValues";
export const FieldsValuesBackendPostfix = "/fieldsValues";
export const ByPlatformBackendPostfix ="/byPlatform";



export function GetAge(startTime, endTime) {
  if (!startTime){
    return null;
  }
  
  startTime = EpochSecondsToDateTime(startTime);

  let ret;
  if (endTime) {
    endTime = EpochSecondsToDateTime(endTime);
    ret = endTime.getTime() - startTime.getTime();
  } else {
    ret = new Date().getTime() - startTime.getTime();
  }
  return TimeToEpochSeconds(ret);
}

export function TimeToEpochSeconds(time) {
  return time ? time / 1000: null;
}

export function EpochSecondsToEpochMilliseconds(time) {
  return time ? time * 1000: null;
}

export function EpochSecondsToDateTime(time) {
  return time ? new Date(EpochSecondsToEpochMilliseconds(time)) : null;
}

export function DateTimeToEpochSeconds(time) {
  return time ? Date.parse(time) / 1000: null;
}

export const DateUTCToLocal = (date) => {
  if (!date) {
    return date;
  }

  // Create a Date object from the input date
  const utcDate = new Date(date);

  // Convert to local time by simply returning the date object
  return utcDate; // JavaScript automatically handles local timezone conversion
};

export const DateLocalToUTC = (date) => {
  if (!date) {
    return date;
  }

  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ));
};

export const StripTime = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const formatEpochToUTC = (epochTime) => {
  // Convert epoch time to Date object
  let date = new Date(EpochSecondsToEpochMilliseconds(epochTime));

  // Manually format the date in the desired format: YYYY-MM-DD HH:mm:ss UTC
  let year = date.getUTCFullYear();
  let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-based
  let day = String(date.getUTCDate()).padStart(2, "0");
  let hours = String(date.getUTCHours()).padStart(2, "0");
  let minutes = String(date.getUTCMinutes()).padStart(2, "0");
  let seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Construct the final formatted string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
};
/* LIBS */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "components/loader";

/* CUSTOMS */
import Card from "components/card";
import Button from "components/button";
import {
  CaptchaBackendPath,
  SearchBackendPostfix,
  GetBackendPostfix,
  ContentBackendPath,
  GetPresignBackendPostfix,
} from "constants/routing/backend";
import { fetchPost } from "lib/fetch";
import { formatBytes } from "lib/formatBytes";
import CaptchaComponent from "components/captcha";

export default function Download({ payload: originalPayload, isPatch, id }) {
  const [payload, setPayload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [captcha, setCaptcha] = useState({});
  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    if (!showCaptcha) {
      return;
    }

    sendCaptchaEvent();
  }, [showCaptcha]);

  const sendCaptchaEvent = () => {
    const ppp = {
      entityType: "content",
      entityId: id,
    };

    setIsLoading(true);
    fetchPost(CaptchaBackendPath + GetBackendPostfix, ppp)
      .then((resp) => {
        setCaptcha(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setCaptcha({});
        setShowCaptcha(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyCaptchaEvent = (coords, clear) => {
    fetchPost(CaptchaBackendPath + SearchBackendPostfix, { coords })
      .then((resp) => {
        setCaptcha(null);
        toast.success("Captcha passed.");
        getPresignedUrls();
      })
      .catch((resp) => {
        toast.error(resp.message);
        sendCaptchaEvent();
      });
  };

  const getPresignedUrls = () => {
    setIsLoading(true);
    fetchPost(ContentBackendPath + GetPresignBackendPostfix, { id })
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setShowCaptcha(false);
        setCaptcha({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fileLinkList = () => {
    if (!payload.fileUrl) {
      return;
    }

    return (
      <div className="col-span-1">
        <h3 className="text-left">File</h3>
        <ol>
          <li className="text-left">
            <Link className="text-left" target="_blank" to={payload.fileUrl.url}>
              Download ({formatBytes(originalPayload.fileUrl.byteSize)})
            </Link>
          </li>
        </ol>
      </div>
    );
  };

  const readmeLinkList = () => {
    if (!payload.readmeUrl) {
      return;
    }

    return (
      <div className="col-span-1">
        <h3 className="text-left">Readme</h3>
        <ol>
          <li className="text-left">
            <Link className="text-left" target="_blank" to={payload.readmeUrl.url}>
              Download ({formatBytes(originalPayload.readmeUrl.byteSize)})
            </Link>
          </li>
        </ol>
      </div>
    );
  };

  const captchaComp = () => {
    return (
      <>
        {!captcha || Object.keys(captcha).length === 0 || (
          <div className="flex justify-center">
            <CaptchaComponent data={captcha} refreshEvent={sendCaptchaEvent} confirmEvent={verifyCaptchaEvent} />
          </div>
        )}

        <div className="flex justify-end">
          {showCaptcha || (
            <Button
              className="rounded-lg"
              onClick={() => {
                setShowCaptcha(true);
              }}
            >
              {" "}
              Get downloads
            </Button>
          )}
        </div>
      </>
    );
  };

  const patchNowButton = () => {
    if (!payload.fileUrl) {
      return;
    }

    if (!isPatch) {
      return;
    }

    const regex = /CRC32:\s*`?([0-9A-Fa-f]{8})`?/g;
    let match;
    let crc32Values = [];
    
    while ((match = regex.exec(originalPayload.dat)) !== null) {
      crc32Values.push(match[1]);
    }

    return (
      <>
        <div className="flex justify-end">
          <a
            href={`/patcher/auto.html?${new URLSearchParams({
              fileUrl: btoa(payload.fileUrl.url), 
              name: btoa(originalPayload.title),
              crc32: btoa(crc32Values),
            
            })}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="rounded-lg">Patch Now</Button>
          </a>
        </div>
      </>
    );
  };

  const margin = "ml-1 mb-3";

  if (!originalPayload || (!originalPayload.fileUrl && !originalPayload.readmeUrl)) {
    return;
  }

  return (
    <Card className={margin} title={"Download"}>
      <Loader isLoading={isLoading} />
      {captchaComp()}

      <div className="lg:grid lg:grid-cols-2 gap-3">
        {readmeLinkList()}
        {fileLinkList()}
      </div>
      {patchNowButton()}
    </Card>
  );
}

Download.propTypes = {
  id: PropTypes.string.isRequired,
  payload: PropTypes.any,
  isPatch: PropTypes.bool,
};

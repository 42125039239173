/** LIBS */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/* CUSTOMS */
import Button from "components/button";

/* CONSTANTS */
import { DeletePostfix, SubmissionFrontendPath } from "constants/routing/frontend";

/* SERVICES */
import { UserContext } from "context/user";

export default function QueueManageButtons({ id, frontendPath, entityType, isDeleted }) {
  const { userContext } = useContext(UserContext);
  const { isLoggedIn } = userContext;

  return (
    <>
      {isLoggedIn ? (
        <div className="flex justify-end m-4 space-x-4">
          <Link className="text-sm" to={SubmissionFrontendPath + frontendPath + `?${new URLSearchParams({ entityId: id })}`}>
            <Button className={"rounded-lg"}>Edit</Button>
          </Link>
          {isDeleted || (
            <Link
              className="text-sm"
              to={SubmissionFrontendPath + DeletePostfix + `?${new URLSearchParams({ entityId: id, entityType: entityType })}`}
            >
              <Button className={"rounded-lg"} secondary={true}>
                Delete
              </Button>
            </Link>
          )}
        </div>
      ) : null}
    </>
  );
}

QueueManageButtons.propTypes = {
  id: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  
  frontendPath: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool,
};

/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import ForumHub from "./hub";
import ForumTopicSearch from "./forum";
import TopicUpsert from "./topicUpsert";
import TopicEntry from "./topicEntry";
import ForumTopicAdvancedSearch from "./advancedSearch";
import PostUpdate from "./postUpdate";

/* CONSTANTS */
import { CreatePostfix, SearchPostfix, TopicFrontendPath, UpdatePostfix } from "constants/routing/frontend";
import ForumTopicUnreadSearch from "./unreadSearch";

/* CONSTANTS */

export default function ForumListRoutes() {
  const hiddenLinks = [
    {
      pathPart: "",
      element: <ForumHub />,
    },
    {
      pathPart: SearchPostfix + "/:tag",
      element: <ForumTopicSearch />,
    },
    {
      pathPart: TopicFrontendPath + "/:id",
      element: <TopicEntry />,
    },
    {
      pathPart: TopicFrontendPath + CreatePostfix,
      element: <TopicUpsert />,
    },
    {
      pathPart: TopicFrontendPath + UpdatePostfix,
      element: <TopicUpsert />,
    },
    {
      pathPart: TopicFrontendPath + SearchPostfix,
      element: <ForumTopicAdvancedSearch />,
    },
    {
      pathPart: TopicFrontendPath + "/unread",
      element: <ForumTopicUnreadSearch />,
    },
    {
      pathPart: "/post" + UpdatePostfix,
      element: <PostUpdate />,
    },
  ];
  return (
    <>
      <SeoHeader 
        pageTitle={"Forum"} 
      />

      <Routes>
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`searchRoute-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}
      </Routes>
    </>
  );
}

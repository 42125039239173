/* LIBS */
import React from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import Card from "components/card";
import FilterItem from "./filterItem";
import Button from "components/button";
import TextBox from "components/textBox";

/* ICONS */
import { FaMagnifyingGlass as SearchIcon } from "react-icons/fa6";

export const FriendlyFilterCard = ({filters, setFilters, setQueryString, searchUri, fieldsUri, onClick, metadata}) => {
  const handleKeyDown = (e) => {
    if (e === null){
      return;
    }

    if (e.key !== "Enter") {
      return;
    }

    onClick();
  };

  return (
    <Card className="m-2" title={"Search Filters"}>
      <div className="text-start ml-1 mb-3">
        <div className="grid grid-cols-3">
          <TextBox 
            id={"advancedFilter"} 
            className="col-span-2 w-full" 
            placeholder={"Simple Query"} 
            onChange={setQueryString} 
            onKeyDown={handleKeyDown}
          />
          <div className="flex items-center">
            <Button 
              onClick={onClick} 
              className="col-span-1 m-3 rounded-lg">
              <SearchIcon />
            </Button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-3">
        {metadata && Object.keys(metadata.filters).map((fieldName, fieldNameI) => {

          if (fieldName.endsWith("Id")){
            return <React.Fragment key={`d-${fieldNameI}`}/>;
          }

          if (metadata.filters[fieldName] === "date") {
            return <React.Fragment key={`d-${fieldNameI}`}/>;
          }

          return (
            <div key={`d-${fieldNameI}`} className="col-span-12 md:col-span-6 lg:col-span-4">
              {<FilterItem 
                filterItem={{field: fieldName}} 
                index={fieldNameI} 
                filters={filters} 
                setFilters={setFilters}
                searchUri={searchUri}
                fieldsUri={fieldsUri}
              />}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

FriendlyFilterCard.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      field: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  setFilters: PropTypes.func,
  setQueryString: PropTypes.func,
  searchUri: PropTypes.string.isRequired,
  fieldsUri: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  metadata: PropTypes.object,
};
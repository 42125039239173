/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Card from "components/card";
import Heading from "components/heading";
import Time from "components/time";
import Tags from "pages/database/tags";
import Button from "components/button";

/* CONSTANTS */
import {
  ContentFrontendPath,
  DatabaseFrontendPath,
  EntryPostfix,
  ForumFrontendPath,
  NewsFrontendPath,
  TopicFrontendPath,
} from "constants/routing/frontend";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";

const NewsCard = ({ payload, id, className, hideDiscussionButton }) => {
  const relatedUrl = (x) => {
    if (!x.relatedUrl) {
      return;
    }

    return (
      <>
        <div className="text-left">
          <Heading>Related Urls</Heading>
          <div>
            {x.relatedUrl &&
              x.relatedUrl.map((x, i) => {
                return (
                  <li key={`bullet-${x.id}-${i}`}>
                    <a href={x} target="_blank" rel="noreferrer">
                      {x}
                    </a>
                  </li>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const content = (item) => {
    if (!item.content) {
      return;
    }

    return (
      <>
        {item.content.map((x, i) => {
          return (
            <Link
              key={`bullet-${x.contentId}-${i}`}
              className="text-sm"
              to={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix + x.contentId}
              title={x.contentTitle}
            >
              <Button className="rounded-lg m-2">
                <div className="flex items-center justify-center">
                  <GotoIcon className="mr-2" /> Content
                </div>
              </Button>
            </Link>
          );
        })}
      </>
    );
  };

  const discussion = (item) => {
    if (!item.linkedEntityType) {
      return;
    }

    if (hideDiscussionButton) {
      return;
    }

    return (
      <>
        <Link className="text-sm" to={ForumFrontendPath + TopicFrontendPath + "/" + item.linkedEntityId}>
          <Button className="rounded-lg m-2">
            <div className="flex items-center justify-center">
              <GotoIcon className="mr-2" /> Discussion
            </div>
          </Button>
        </Link>
      </>
    );
  };

  const images = (x) => {
    if (!x || !x.imagesUrl) {
      return;
    }

    return (
      <div className="flex justify-center flex-wrap">
        {x.imagesUrl.map((y, i) => {
          return <img key={`image-${i}`} className="p-3 max-h-64 max-w-full object-contain" loading="lazy" src={y.url} alt="" />;
        })}
      </div>
    );
  };

  return (
    <>
      <Card className={className} title={payload.title} to={DatabaseFrontendPath + NewsFrontendPath + EntryPostfix + (id || payload.id)}>
        <div className="flex items-center justify-center italic text-sm">
          <div className="m-1">Published on:</div>
          <Time time={payload.createdDate} />
        </div>
        {images(payload)}
        <Markdown className={"text-contrastChangeText text-left break-words"}>{payload.text}</Markdown>

        <div className="grid grid-cols-12 gap-1 w-full">
          <div className="col-span-7">{relatedUrl(payload)}</div>
          <div className="col-span-5">{<Tags payload={payload} />}</div>
        </div>

        <div className="flex items-center justify-end">
          {discussion(payload)}
          {content(payload)}
        </div>
      </Card>
    </>
  );
};

NewsCard.propTypes = {
  id: PropTypes.string,
  payload: PropTypes.object.isRequired,
  className: PropTypes.string,
  hideDiscussionButton: PropTypes.bool,
};

export default NewsCard;

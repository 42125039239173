/** LIBS */
import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Paginator from "components/paginator";
import { fetchPost } from "lib/fetch";
import { requestFormatFilter } from "lib/elastic";
import Button from "components/button";
import { SortableTable } from "components/sortableTable";
import InvisibleScroll from "components/invisibleScroll";

/* CONSTANTS */
import { pageSize } from "constants/models";
import Loader from "components/loader";
import { FieldValuesBackendPostfix, ForumBackendPath, MetadataBackendPostfix, SearchBackendPostfix } from "constants/routing/backend";
import { EntryPostfix, ForumFrontendPath, UserFrontendPath } from "constants/routing/frontend";

/* SERVICES */
import { UserContext } from "context/user";
import { FriendlyFilterCard } from "components/friendlyFilterCard";
import { GenerateStatusIcons } from "./helper";

export default function ForumTopicAdvancedSearch() {
  const [page, setPage] = useState(0);
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField, setSortField] = useState("updatedDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [filters, setFilters] = useState([]);
  const [queryString, setQueryString] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const { userContext } = useContext(UserContext);
  const { lastReadDateTime } = userContext;

  useEffect(() => {
    metadataRequest();
  }, []);

  useEffect(() => {
    hackRequest();
  }, [page, sortDirection, sortField, filters]);

  const metadataRequest = () => {
    setIsLoading(true);
    fetchPost(ForumBackendPath + "/topic" + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const hackRequest = () => {
    setIsLoading(true);

    const temp = [...filters];

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(temp),
      queryString: queryString,
    };

    fetchPost(ForumBackendPath + "/topic" + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      label: "Status",
      field: "",
      width: "w-20",
      dataType: "custom",
      custom: (data) => {
        return GenerateStatusIcons(data, lastReadDateTime);
      },
    },
    {
      label: "Topic",
      idField: "id",
      field: "title",
      width: "w-96 max-xl:w-auto",
      dataType: "link",
      to: (data) => {
        return `${ForumFrontendPath}/topic/`;
      },
    },
    {
      label: "Created By",
      idField: "createdByUserId",
      field: "createdByUsername",
      width: "w-auto max-xl:hidden",
      dataType: "link",
      to: `${UserFrontendPath}${EntryPostfix}`,
    },
    {
      label: "Created Time",
      field: "createdDate",
      width: "w-auto max-xl:hidden",
      dataType: "dateTimeAgo",
    },
    {
      label: "Last Post By",
      idField: "updatedByUserId",
      field: "updatedByUsername",
      width: "w-auto",
      dataType: "link",
      to: `${UserFrontendPath}${EntryPostfix}`,
    },
    {
      label: "Last Post Time",
      field: "updatedDate",
      width: "w-auto max-sm:hidden",
      dataType: "dateTimeAgo",
    },
    {
      label: "Replies",
      field: "posts",
      width: "w-28 max-xl:hidden",
      dataType: "write",
    },
    {
      label: "Views",
      field: "views",
      width: "w-28 max-xl:hidden",
      dataType: "write",
    },
  ];

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  if (!payload) {
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={"Forum - Advanced Search"} />
      <Title>Advanced Search</Title>
      <Loader isLoading={isLoading} />

      <div className="flex items-center justify-end">
        <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
      </div>

      <div className="col-span-12">
        <FriendlyFilterCard
          filters={filters}
          setFilters={setFilters}
          setQueryString={setQueryString}
          searchUri={ForumBackendPath + "/topic"}
          fieldsUri={ForumBackendPath + "/topic" + FieldValuesBackendPostfix}
          onClick={hackRequest}
          metadata={metadata}
        />
      </div>

      <InvisibleScroll ref={gameMultiSelectRef} />

      <SortableTable
        data={payload && payload.entries}
        metadataColumn={metadata && metadata.columns}
        columns={columns}
        setSortField={setSortField}
        setSortDirection={setSortDirection}
        sortField={sortField}
        sortDirection={sortDirection}
      />
      <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
    </>
  );
}

/** LIBS */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Download from "../download";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import Review from "../review";
import SeoHeader from "components/seoHeader";
import Bob from "../bob";
import Credit from "../credit";

/* CONSTANTS */
import { ContentBackendPath, GetBackendPostfix } from "constants/routing/backend";
import VideoPlayer from "components/videoPlayer";
import { removeMarkdownFormatting } from "lib/strings";
import HistoryCard from "pages/history";
import { ContentFrontendPath } from "constants/routing/frontend";

/* SERVICES */
/*import { UserContext } from "context/user";*/
import QueueManageButtons from "../queueManageButton";
import DeletionNoticeCard from "../deletionNotice";
import Gallery from "components/gallery";

export default function ContentEntry() {
  const [payloadHack, setPayloadHack] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  /*const { userContext } = useContext(UserContext);
  const { /username , permissions } = userContext;*/

  useEffect(() => {
    hackGetRequest();
  }, []);

  const hackGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(ContentBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadHack(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadHack(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contentDescription = () => {
    if (!payloadHack || !payloadHack.description) {
      return;
    }

    return (
      <Card className={margin} title={"Content Description"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{payloadHack && payloadHack.description}</Markdown>
        </div>
      </Card>
    );
  };

  const screenshots = () => {
    if (!payloadHack) {
      return;
    }

    if (!payloadHack.imagesUrl) {
      return;
    }

    return (
      <Card className={margin} title={"Screenshots"}>
        <Gallery imageUrls={payloadHack.imagesUrl.map(x => x.url)}/>
      </Card>
    );
  };

  const videoPlayerCard = () => {
    if (!payloadHack) {
      return;
    }

    if (!payloadHack.videoUrl) {
      return;
    }

    return (
      <Card className={margin} title={"Video"}>
        <div className="flex items-center justify-center h-auto">
          <div className="w-full max-w-4xl">
            <VideoPlayer className="w-full h-96 border-none" videoUrl={payloadHack.videoUrl} />
          </div>
        </div>
      </Card>
    );
  };

  const datInfo = () => {
    if (!payloadHack) {
      return;
    }

    if (!payloadHack.dat) {
      return;
    }

    return (
      <Card className={`${margin} break-words`} title={"DAT Information"}>
        <Markdown className="text-left mr-3 text-contrastChangeText">{payloadHack.dat}</Markdown>
      </Card>
    );
  };

  // Ensure card width is consistent, despite excessively strings
  const cardClass = "lg:w-1/2 w-full";

  const isPatch = (payloadHack) && (payloadHack.tags.includes("Hack") || payloadHack.tags.includes("Translation"));

  return (
    <>
      {payloadHack ? (
        <SeoHeader
          pageTitle={"Content"}
          pageSectionTitle={payloadHack.title}
          description={removeMarkdownFormatting(payloadHack.description)}
        />
      ) : null}

      <Title>Content Entry</Title>

      {payloadHack ? (
        <QueueManageButtons 
          id={id} 
          frontendPath={ContentFrontendPath} 
          entityType={"content"} 
          isDeleted={payloadHack.isDeleted} 
        />
      ) : null}

      <Loader isLoading={isLoading} />
      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {<Bob payload={payloadHack} />}
          {screenshots()}
          {<Download id={id} payload={payloadHack} isPatch={isPatch} />}
          {videoPlayerCard()}
          {<HistoryCard payload={payloadHack} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadHack}/>}
          {contentDescription()}
          {datInfo()}
          {payloadHack ? <Credit payload={payloadHack.credits} /> : null}
        </div>
      </div>
      {<Review payload={payloadHack} id={id} entityType={"content"}/>}
    </>
  );
}

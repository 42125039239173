/** LIBS */
import React from "react";
import PropTypes from "prop-types";

export default function Heading({ className, children, ...otherProps }) {
  return (
    <h2
      {...otherProps}
      className={`font-gameFont text-heading text-sm mb-2 mt-2 ${className || ""}`}
    >
      {children}
    </h2>
  );
}

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

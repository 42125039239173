import { AddHttpProtocol } from "lib/url";

const { 
  REACT_APP_AUTH_SSL_ENABLE, 
  REACT_APP_HOST, 
  REACT_APP_PORT } = process.env;

export const AuthServerUrl = `${AddHttpProtocol(REACT_APP_AUTH_SSL_ENABLE)}${REACT_APP_HOST}:${REACT_APP_PORT}`;

export const HomeFrontendPath = "/";
export const DatabaseFrontendPath = "/database";
export const AdvancedSearchFrontendPath = "/advancedSearch";
export const AuthorFrontendPath = "/author";
export const ForumFrontendPath = "/forum";
export const MfaFrontendPath = "/mfa";

export const ResourceFrontendPath = "/resource";
export const TranslationFrontendPath = "/translation";
export const ContentFrontendPath = "/content";
export const GamesFrontendPath = "/game";
export const ReviewFrontendPath = "/review";
export const FontsFrontendPath = "/fonts";
export const AbandonedFrontendPath = "/abandoned";
export const NewsFrontendPath = "/news";
export const SubmissionFrontendPath = "/submission";
export const SubmissionQueueFrontendPath = "/queue";
export const ChangesFrontendPath = "/changes";

export const AdminFrontendPath = "/admin";
export const UserFrontendPath = "/user";
export const BlockListFrontendPath = "/blockist";
export const LoginFrontendPath = "/login";
export const LogoutFrontendPath = "/logout";
export const RegistrationFrontendPath = "/registration";
export const ResetFrontendPath = "/reset";
export const TopicFrontendPath = "/topic";

export const EntryPostfix = "/entry/";
export const ListPostfix = "/list";
export const CommentPostfix = "/comment";
export const DeletePostfix = "/delete";
export const CreatePostfix = "/create";
export const UpdatePostfix = "/update";
export const SearchPostfix = "/search";

export const HelpFrontendPath = "/help";
export const GettingStartedFrontendPath = "/start";
export const RulesFrontendPath = "/rules";
export const DictionaryFrontendPath = "/dictionary";
export const FaqFrontendPath = "/faq";

/** LIBS */
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import Title from "components/title";
import Button from "components/button";
import Card from "components/card";

/* CONSTANTS */
import { LoginMfaBackendPath } from "constants/routing/backend";
import { UserContext } from "context/user";
import { ForumFrontendPath, LoginFrontendPath, UserFrontendPath } from "constants/routing/frontend";
import TextBox from "components/textBox/index";

export default function Mfa() {
  const [passcode, setPasscode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { userContext, setUserContext } = useContext(UserContext);
  const { isLoggedIn, mfaEnabled } = userContext;

  const mfaRequest = () => {
    setIsLoading(true);

    const body = {
      passcode: passcode,
    };

    fetchPost(LoginMfaBackendPath, body)
      .then((resp) => {
        const { isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled } = resp;
        const userData = { isLoggedIn, username, permissions, userId, lastReadDateTime, mfaEnabled};
        setUserContext(userData);

        toast.success("Logged in");
        navigate(ForumFrontendPath);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setUserContext({ isLoggedIn: false, permissions: [], username: null, userId: null, lastReadDateTime: 0, mfaEnabled: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /*if (!mfaEnabled){
    navigate(UserFrontendPath + LoginFrontendPath);
  }

  if (isLoggedIn){
    navigate(ForumFrontendPath);
  }*/

  return (
    <>
      <SeoHeader pageTitle={"MFA Challenge"} />
      <Loader isLoading={isLoading} />

      <Title>MFA Challenge</Title>

      <Card title="Form">

        <div className="mb-3">
            Please open your one time password app (such as authenticator) and enter your passcode.
        </div>
        <div className="col-span-12 flex justify-center">
          <TextBox
            id={"username-textBox"}
            className={"mb-3 w-full max-w-xs"}
            placeholder={"Passcode"}
            type={passcode}
            onChange={setPasscode}
          />
        </div>

        <div className="col-span-12 flex justify-center">
          <Button className={"rounded-lg max-w-xs"} onClick={mfaRequest}>
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
}

/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Title from "components/title";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Loader from "components/loader";
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import { GetBackendPostfix, UserBackendPostfix, AdminBackendPath, SessionBackendPostfix } from "constants/routing/backend";
import HistoryCard from "pages/history";
import { AdminFrontendPath, DeletePostfix, UpdatePostfix, UserFrontendPath } from "constants/routing/frontend";
import DeletionNoticeCard from "../../database/deletionNotice";
import { Table } from "components/table";
import Heading from "components/heading";
import Time from "components/time";
import Button from "components/button";
import { PermissionAdmin } from "constants/permissions";
import { FriendlyColumns } from "components/friendlyColumns";

/* SERVICES */
import { UserContext } from "context/user";

export default function UserEntry() {
  const [payloadUser, setPayloadUser] = useState(null);
  const [payloadSessions, setPayloadSessions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { isLoggedIn, permissions } = userContext;

  useEffect(() => {
    userGetRequest();
    userSessionGetRequest();
  }, [id]);

  const userGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(AdminBackendPath + UserBackendPostfix + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadUser(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userSessionGetRequest = () => {
    setIsLoading(true);

    const body = {
      userId: id,
    };

    fetchPost(AdminBackendPath + SessionBackendPostfix + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadSessions(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadSessions(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userSessionKill = (sessionId) => {
    setIsLoading(true);

    const body = {
      userId: id,
      sessionId: sessionId,
    };

    fetchPost(AdminBackendPath + SessionBackendPostfix + DeletePostfix, body)
      .then((resp) => {
        toast.success("Session killed");
        userSessionGetRequest();
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userAbout = () => {
    if (!payloadUser) {
      return;
    }

    const profileImage = () => {
      if (!payloadUser.thumbnailUrl) {
        return;
      }

      return (
        <div className="flex items-center justify-center">
          <img src={payloadUser.thumbnailUrl.url} alt="" />
        </div>
      );
    };

    const headings = [
      {
        label: "Email",
        field: "email",
        width: "col-span-6",
        dataType: "write",
      },
      {
        label: "Failure Count",
        field: "failureCount",
        width: "col-span-6",
        dataType: "write",
      },
      {
        label: "Tags",
        field: "tags",
        width: "col-start-1 col-span-12",
        dataType: "pills",
      },
      {
        label: "Permissions",
        field: "permissions",
        width: "col-start-1 col-span-12",
        dataType: "pills",
      },
    ];

    return (
      <Card className={margin} title={"Details"}>
        <div>
          {profileImage()}
          <div className="grid grid-cols-12 gap-1 my-3 w-full">
            <FriendlyColumns data={payloadUser} columns={headings} keyPrefix={""} />
          </div>
        </div>
      </Card>
    );
  };

  const headerColClass = "px-6 py-3 text-left tracking-wider";
  const rightText = "px-6 text-left break-words";

  const userSession = () => {
    return (
      <>
        <Heading>Active Sessions</Heading>
        <Table>
          <thead>
            <tr>
              <td className={`w-20 ${headerColClass}`}>IP Address</td>
              <td className={`w-20 ${headerColClass}`}>User Agent</td>
              <td className={`w-20 ${headerColClass}`}>Kill Session</td>
            </tr>
          </thead>
          <tbody>
            {payloadSessions &&
              payloadSessions.map((c, i) => {
                return (
                  <tr className="ml-3" key={`activeSessions-${i}`}>
                    <td className={rightText}>{c.ipAddress}</td>
                    <td className={rightText}>{c.userAgent}</td>
                    <td className={rightText}>
                      <Button onClick={() => userSessionKill(c.sessionId)} className="rounded-lg" secondary={true}>
                        Kill Session
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const topUserAgentCard = () => {
    if (!payloadUser || !payloadUser.userAgents) {
      return;
    }

    return (
      <>
        <Card className={margin} title={"User Agents"}>
          <ol className="text-left">
            {payloadUser.userAgents.map((x, i) => {
              return <li key={`userAgents-${i}`}>{x}</li>;
            })}
          </ol>
        </Card>
      </>
    );
  };

  const topIpAddressCard = () => {
    if (!payloadUser || !payloadUser.ipAddresses) {
      return;
    }

    return (
      <>
        <Card className={margin} title={"IP Addresses"}>
          <ol className="text-left">
            {payloadUser.ipAddresses.map((x, i) => {
              return <li key={`ipAddresses-${i}`}>{x}</li>;
            })}
          </ol>
        </Card>
      </>
    );
  };

  const loginActivityCard = () => {
    if (!payloadUser || !payloadUser.loginHistory) {
      return;
    }

    payloadUser.loginHistory.sort((a, b) => b.dateTime - a.dateTime);

    return (
      <>
        <Heading>Login History</Heading>
        <Table>
          <thead>
            <tr>
              <td className={`w-20 ${headerColClass}`}>Time</td>
              <td className={`w-20 ${headerColClass}`}>IP Address</td>
              <td className={`w-80 ${headerColClass}`}>User Agent</td>
            </tr>
          </thead>
          <tbody>
            {payloadUser.loginHistory.map((c, i) => {
              return (
                <tr className="ml-3" key={`loginHistory-${i}`}>
                  <td className={rightText}>
                    <Time time={c.dateTime} customFormat="YYYY MMM DD HH:mm UTC" />
                  </td>
                  <td className={rightText}>{c.ipAddress}</td>
                  <td className={rightText}>{c.userAgent}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  if (!isLoggedIn) {
    return;
  }

  if (!permissions.includes(PermissionAdmin)) {
    toast.error("You do not have permission to view this page.");
    return;
  }

  var authorName = payloadUser && payloadUser.username;

  return (
    <>
      <SeoHeader pageTitle={"User"} pageSectionTitle={payloadUser && payloadUser.username} />

      <Loader isLoading={isLoading} />

      <Title>{authorName}</Title>

      {payloadUser ? (
        <div className="flex justify-end m-4 space-x-4">
          <Link className="text-sm" to={AdminFrontendPath + UserFrontendPath + UpdatePostfix + "/" + id}>
            <Button className={"rounded-lg"}>Edit</Button>
          </Link>
        </div>
      ) : null}

      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {userAbout()}
          {<HistoryCard payload={payloadUser} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {topUserAgentCard()}
          {topIpAddressCard()}
          {<DeletionNoticeCard payload={payloadUser} />}
        </div>
      </div>

      <div className={`$w-full col-span-6 ${margin}`}>
        {userSession()}
        {loginActivityCard()}
      </div>
    </>
  );
}

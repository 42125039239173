/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyFilterCard } from "components/friendlyFilterCard";
import InvisibleScroll from "components/invisibleScroll";
import NewsCard from "./newsCard";
import { requestFormatFilter } from "lib/elastic";

/* CONSTANTS */
import {
  FieldValuesBackendPostfix,
  FriendlyBackendPostfix,
  MetadataBackendPostfix,
  NewsBackendPath,
  SearchBackendPostfix,
} from "constants/routing/backend";
import { pageSize } from "constants/models";

export default function NewsEntry() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("createdDate");
  const [sortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    fetchPost(NewsBackendPath + FriendlyBackendPostfix + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);

        const temp = Array(Object.keys(resp.filters).length).fill(null);

        setFilters(temp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    if (!metadata) {
      return;
    }

    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(NewsBackendPath + FriendlyBackendPostfix + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <Title>News</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={NewsBackendPath}
            fieldsUri={NewsBackendPath + FieldValuesBackendPostfix}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>

        <InvisibleScroll ref={gameMultiSelectRef} />

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>

          {payload &&
            payload.entries &&
            payload.entries.map((x, i) => {
              return <NewsCard key={`news-${i}`} payload={x} className={"m-2"} />;
            })}
          <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
        </div>
      </div>
    </>
  );
}

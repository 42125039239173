/** LIBS */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";

/* CONSTANTS */
import Title from "components/title";
import TextBox from "components/textBox";
import Card from "components/card";
import Checkbox from "components/checkBox";

import { RegistrationBackendPath } from "constants/routing/backend";
import Button from "components/button";
import InlineLoader from "components/loaderInline";

/* ICONS */
import { FaCheckCircle as SuccessIcon } from "react-icons/fa";
import { HiXCircle as FailureIcon } from "react-icons/hi";
import { LoginFrontendPath, UserFrontendPath } from "constants/routing/frontend";
import ValidatePasswords from "./validatePasswords";

export default function Registration2() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [registration1Success, setRegistration1Success] = useState("");

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [isPasswordGood, setIsPasswordGood] = useState(false);

  const { jwtToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ValidatePasswords({password1, password2, setIsPasswordGood, setPasswordErrors});
  }, [password1, password2]);

  useEffect(() => {
    validateJWT();
  }, []);

  const validateJWT = () => {
    setIsLoading(true);

    const body = {
      jwtToken: jwtToken,
    };

    fetchPost(RegistrationBackendPath + "/signUp/2", body)
      .then((resp) => {
        setRegistration1Success(true);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const registerRequest = () => {
    setIsLoading(true);

    const body = {
      jwtToken: jwtToken,
      password: password1,
    };

    fetchPost(RegistrationBackendPath + "/signUp/3", body)
      .then((resp) => {
        toast.success("Registration complete! Try logging in.");
        navigate(`${UserFrontendPath + LoginFrontendPath}`);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const passwordType = showPassword ? "" : "password";

  if (!registration1Success) {
    return;
  }

  const enableRegisterButton = isPasswordGood;

  return (
    <>
      <SeoHeader pageTitle={"Registration"} />
      <Loader isLoading={isLoading} />

      <Title>Registration</Title>

      <div className="space-y-4 mb-3">
        <Card title="Preface">
          {"You're almost done! To complete the signup process, please complete the security requirements below."}
        </Card>
        <Card title="Password">
          <div className="grid grid-cols-11 gap-3">
            <TextBox
              id={"textBox-password1"}
              className={"w-full sm:col-span-5 col-span-12"}
              placeholder={"Password"}
              type={passwordType}
              value={password1}
              onChange={setPassword1}
            />

            <TextBox
              id={"textBox-password2"}
              className={"w-full sm:col-span-5 col-span-12"}
              placeholder={"Password (Re-enter)"}
              type={passwordType}
              value={password2}
              onChange={setPassword2}
            />

            <div data-tooltip-id="tooltip" className="w-10 max-md:hidden grid grid-cols-1 gap-3">
              <InlineLoader isSuccess={isPasswordGood} isLoading={false} />
              <Tooltip id="tooltip" place="top" effect="solid" className="text-left">
                {passwordErrors}
              </Tooltip>
            </div>

            <div className="col-span-12 w-full">
              <Checkbox label="Show Password" checked={showPassword} onChange={setShowPassword} />
            </div>
          </div>

          <div className="flex justify-start gap-3 md:hidden mt-3 text-left">
            <div>{passwordErrors}</div>
          </div>
        </Card>
      </div>

      <div className="col-span-12 flex justify-end mt-5 text-xs">
        <Button className="mt-3 rounded-lg" disabled={!enableRegisterButton} onClick={registerRequest}>
          Save
        </Button>
      </div>
    </>
  );
}

/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import Button from "components/button";
import { ToLabelValue } from "lib/strings";
import TextBox from "components/textBox";
import Checkbox from "components/checkBox";
import DropzoneComponent from "components/dropZone";
import { processFileUrl } from "pages/submission/submit/fileHandle";
import MultiSelect from "components/multiSelect";
import InlineLoader from "components/loaderInline";
import ValidatePasswords from "./validatePasswords";

/* CONSTANTS */
import {
  BackendEndpoint,
  SearchBackendPostfix,
  UpdateBackendPostfix,
  UserBackendPath,
} from "constants/routing/backend";
import { allowedImageType } from "constants/models";

/* SERVICES */
import { UserContext } from "context/user";

export default function UserEdit() {
  const [isLoading, setIsLoading] = useState(false);

  const [permissionList, setPermissionList] = useState("");

  const [avatar, setAvatar] = useState([]);

  const [username1, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [permissions1, setPermissions] = useState([]);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [isPasswordGood, setIsPasswordGood] = useState(false);

  const [otpSecret, setOtpSecret] = useState("");
  const [otpSecretImage, setOtpSecretImage] = useState("");

  const [securityAnswer, setSecurityAnswer] = useState("");

  const { userContext, setUserContext } = useContext(UserContext);
  const { username, userId, mfaEnabled } = userContext;

  const { id } = useParams();

  useEffect(() => {
    userRequest();
    permissionRequest();
    generateQRInformation();
  }, []);

  useEffect(() => {
    ValidatePasswords({password1, password2, setIsPasswordGood, setPasswordErrors});
  }, [password1, password2]);

  const userRequest = () => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(UserBackendPath + "/getForEditing", body)
      .then((d) => {
        setUsername(d.username);
        setEmail(d.email);
        processFileUrl(d.thumbnailUrl, setAvatar);
        setPermissions(d.permissions);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const permissionRequest = () => {
    setIsLoading(true);

    fetchPost(BackendEndpoint + "/permission" + SearchBackendPostfix, null)
      .then((d) => {
        setPermissionList(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const avatarUpdateRequest = () => {
    setIsLoading(true);

    var thumbnailUrl = null;

    if (avatar && avatar.length === 1) {
      thumbnailUrl = avatar[0].ConvertToRequest();
    }

    const body = {
      userId: id,
      thumbnailUrl: thumbnailUrl,
    };

    fetchPost(UserBackendPath + UpdateBackendPostfix + "/avatar", body)
      .then((d) => {
        toast.success(d.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const permissionUpdateRequest = () => {
    setIsLoading(true);

    const body = {
      userId: id,
      permissions: permissions1,
    };

    fetchPost(UserBackendPath + UpdateBackendPostfix + "/permissions", body)
      .then((d) => {
        toast.success(d.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const usernameUpdateRequest = () => {
    if (password1 !== password2) {
      return;
    }

    setIsLoading(true);

    const body = {
      userId: id,
      username: username1,
    };

    fetchPost(UserBackendPath + UpdateBackendPostfix + "/username", body)
      .then((d) => {
        toast.success(d.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  const passwordUpdateRequest = () => {
    setIsLoading(true);

    const body = {
      password: password1,
    };

    fetchPost(UserBackendPath + UpdateBackendPostfix + "/password", body)
      .then((d) => {
        toast.success(d.message);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function splitIntoChunks(str, chunkSize) {
    const chunks = [];
    for (let i = 0; i < str.length; i += chunkSize) {
      chunks.push(str.substring(i, i + chunkSize));
    }
    return chunks.join(" ");
  }

  const generateQRInformation = () => {
    setIsLoading(true);

    fetchPost(UserBackendPath + "/qr", null)
      .then((resp) => {
        setOtpSecret(resp.otpSecret);
        setOtpSecretImage(resp.otpSecretQrCode);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const enableMFARequest = () => {
    setIsLoading(true);

    const body = {
      passcode: securityAnswer,
    };

    fetchPost(UserBackendPath + "/otpEnable", body)
      .then((resp) => {
        toast.success(resp.message);

        userContext.mfaEnabled = true;
        setUserContext(userContext);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const disableMFARequest = () => {
    setIsLoading(true);

    fetchPost(UserBackendPath + "/otpDisable", null)
      .then((resp) => {
        toast.success(resp.message);

        userContext.mfaEnabled = false;
        setUserContext(userContext);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const passwordType = showPassword ? "" : "password";

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{"Edit " + username1}</Title>

      <Card className="m-8" title={"Avatar"}>
        <DropzoneComponent 
          files={avatar} 
          setFiles={setAvatar} 
          allowedFileTypes={allowedImageType}
        />

        <div className="flex justify-end space-x-4 mt-4">
          <Button className="mt-3 rounded-lg" onClick={avatarUpdateRequest}>
            Save
          </Button>
        </div>
      </Card>

      <Card className="m-8" title={"Username"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={username1}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Username"}
            onChange={setUsername}
          />
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <div
            data-tooltip-id="username-change-tooltip"
            data-tooltip-content={"Updating the username will kill all sessions for the user."}
          >       
            <Button className="mt-3 rounded-lg" onClick={usernameUpdateRequest}>
            Save
            </Button>
            <Tooltip id="username-change-tooltip" place="top" className="z-50" />

          </div>
        </div>
      </Card>


      <Card className="m-8" title={"Email"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={email}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Content Title"}
            onChange={setEmail}
          />
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button className="mt-3 rounded-lg" onClick={() => alert()}>
            Save
          </Button>
        </div>
      </Card>

      <Card className="m-8" title={"Permission"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <MultiSelect
            className="m-1 col-span-6"
            placeholder="Permissions"
            isMulti={true}
            onSelect={(e) => {
              const temp = [...permissions1, e[e.length - 1].value];
              setPermissions(temp);
            }}
            onRemove={(_, meta) => {
              const temp = [...permissions1];
              const index = temp.indexOf(meta.removedValue.value);
              temp.splice(index, 1);
              setPermissions(temp);
            }}
            options={ToLabelValue(permissionList)}
            value={ToLabelValue(permissions1)}
            onInputChange={() => {}}
          />
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <div
            data-tooltip-id="permission-change-tooltip"
            data-tooltip-content={"Updating permissions will kill all sessions for the user."}
          >            
            <Button className="mt-3 rounded-lg" onClick={permissionUpdateRequest}>
            Save
            </Button>
          </div>
          <Tooltip id="permission-change-tooltip" place="top" className="z-50" />
        </div>
      </Card>

      {userId === id ? <Card className="m-8" title={"Reset Password"}>
        <div className="grid grid-cols-11 gap-3">
          <TextBox
            id={"textBox-password1"}
            className={"w-full sm:col-span-5 col-span-12"}
            placeholder={"Password"}
            type={passwordType}
            value={password1}
            onChange={setPassword1}
          />

          <TextBox
            id={"textBox-password2"}
            className={"w-full sm:col-span-5 col-span-12"}
            placeholder={"Password (Re-enter)"}
            type={passwordType}
            value={password2}
            onChange={setPassword2}
          />

          <div data-tooltip-id="tooltip" className="w-10 max-md:hidden grid grid-cols-1 gap-3">
            <InlineLoader isSuccess={isPasswordGood} isLoading={false} />
            <Tooltip id="tooltip" place="top" effect="solid" className="text-left">
              {passwordErrors}
            </Tooltip>
          </div>

          <div className="col-span-12 w-full">
            <Checkbox label="Show Password" checked={showPassword} onChange={setShowPassword} />
          </div>
        </div>

        <div className="flex justify-start gap-3 md:hidden mt-3 text-left">
          <div>{passwordErrors}</div>
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button className="mt-3 rounded-lg" onClick={passwordUpdateRequest}>
            Save
          </Button>
        </div>
      </Card>: null}

      {!mfaEnabled && userId === id ?
        <Card className="m-8" title="MFA Is Disabled">
          <div>
            {"You currently don't have MFA enabled. This is an optional feature you can enable and disable at will to enhance security."}
          </div>
          <div>
            {`To enable, please scan the following QR code with a TOTP app such as Authenticator
             or enter the secret key manually. You do not necessarily need a mobile app for this.`}
          </div>
          <div className="grid grid-cols-12 flex justify-center">
            <div className="col-span-12 my-auto mx-auto p-3">
              <div className="h-64 w-64 overflow-hidden">
                <img src={otpSecretImage} alt="" className="h-full border-8 border-white pixelImage" />
              </div>
            </div>
            <div className="col-span-12">{splitIntoChunks(otpSecret, 4)}</div>
          </div>

          <div className="col-span-12 flex justify-center mt-3">
            <TextBox
              id={"username-textBox"}
              className={"mb-3 w-full max-w-xs"}
              placeholder={"Security Answer"}
              value={securityAnswer}
              onChange={setSecurityAnswer}
            />
          </div>

          <div className="col-span-12 flex justify-end mt-5 text-xs">
            <Button className="mt-3 rounded-lg" onClick={enableMFARequest}>Enable</Button>
          </div>
        </Card>
        : null}

      {mfaEnabled && userId === id ?
        <Card className="m-8" title="MFA Is Enabled">
          <div>
            {"You currently have MFA enabled. This is an optional feature you can enable and disable at will."}
          </div>

          <div className="col-span-12 flex justify-end mt-5 text-xs">
            <Button className="mt-3 rounded-lg" onClick={disableMFARequest}>Disable</Button>
          </div>
        </Card>
        : null}
    </>
  );
}
